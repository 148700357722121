import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from '../../constants/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = ({ setUser }) => {
  const { language } = navigator;
  const classes = useStyles();

  return (
    <Page className={classes.root} title={getLocaleString('login', language)}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
              remember: false
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(getLocaleString('emailValid', language))
                .max(255)
                .required(getLocaleString('emailRequired', language)),
              password: Yup.string()
                .max(255)
                .required(getLocaleString('passwordRequired', language))
            })}
            onSubmit={(values, actions) => {
              axios
                .post(
                  `${BASE_URL}/authenticate?remember=${values.remember}`,
                  values
                )
                .then(response => {
                  setUser(response.data);
                })
                .catch(() => {
                  toast.error(getLocaleString('signInFail', language));
                  actions.setSubmitting(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {getLocaleString('signIn', language)}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {getLocaleString('signInSub', language)}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={getLocaleString('emailAddress', language)}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={getLocaleString('password', language)}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box alignItems="center" display="flex" ml={-1}>
                  <Checkbox
                    checked={values.remember}
                    name="remember"
                    onChange={handleChange}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {getLocaleString('stayLoggedIn', language)}
                  </Typography>
                </Box>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {getLocaleString('signIn', language)}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
