import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import getLocaleString from 'src/utils/getLocaleString';
import InspectionDetail from 'src/components/InspectionDetail';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  green: {
    backgroundColor: 'green'
  },
  red: {
    backgroundColor: 'red'
  },
  orange: {
    backgroundColor: 'orange'
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

const LatestInspections = ({
  threshold,
  inspections,
  className,
  user,
  ...rest
}) => {
  const classes = useStyles();

  const [selectedInspection, setSelectedInspection] = useState(null);

  const getClass = score => {
    if (score < threshold) {
      return classes.red;
    }
    if (score < 100) {
      return classes.orange;
    }
    return classes.green;
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={getLocaleString('latestInspections', user.language)} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {getLocaleString('inspector', user.language)}
                </TableCell>
                <TableCell>
                  {getLocaleString('customer', user.language)}
                </TableCell>
                <TableCell>
                  {getLocaleString('building', user.language)}
                </TableCell>
                <TableCell>{getLocaleString('date', user.language)}</TableCell>
                <TableCell>
                  {getLocaleString('startTime', user.language)}
                </TableCell>
                <TableCell>
                  {getLocaleString('endTime', user.language)}
                </TableCell>
                <TableCell>{getLocaleString('score', user.language)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inspections.map(inspection => (
                <TableRow
                  hover
                  key={inspection.id}
                  className={classes.tableRow}
                  onClick={() => setSelectedInspection(inspection)}
                >
                  <TableCell>{inspection.inspector.name}</TableCell>
                  <TableCell>{inspection.building.customer.name}</TableCell>
                  <TableCell>{inspection.building.name}</TableCell>
                  <TableCell>
                    {moment(inspection.date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(inspection.startTime).format('HH:mm')}
                  </TableCell>
                  <TableCell>
                    {moment(inspection.date).format('HH:mm')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      label={`${+inspection.score.toFixed(2)}%`}
                      size="small"
                      classes={{ colorPrimary: getClass(inspection.score) }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <InspectionDetail
        selectedInspection={selectedInspection}
        setSelectedInspection={setSelectedInspection}
        threshold={threshold}
        user={user}
      />
    </Card>
  );
};

LatestInspections.propTypes = {
  className: PropTypes.string
};

export default LatestInspections;
