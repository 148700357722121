import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, makeStyles } from '@material-ui/core';

import axios from 'axios';
import { toast } from 'material-react-toastify';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import EmployeeProfile from './EmployeeProfile';
import EmployeeProfileDetails from './EmployeeProfileDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  carouselItem: {
    paddingLeft: 40,
    paddingRight: 40
  },
  carouselInspectionItem: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 24
  }
}));

const EmployeeView = ({ user }) => {
  const classes = useStyles();
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (employeeId === '0') {
      setEmployee({
        id: 0,
        name: '',
        company: user.company
      });
    } else {
      axios
        .get(`${BASE_URL}/employee/${employeeId}`)
        .then(response => {
          setEmployee(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, [employeeId]);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('employee', user.language)}
    >
      {!employee ? (
        <></>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {employeeId !== '0' ? (
              <Grid item lg={4} md={6} xs={12}>
                <EmployeeProfile user={user} employee={employee} />
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              item
              lg={employeeId !== '0' ? 8 : 12}
              md={employeeId !== '0' ? 6 : 12}
              xs={12}
            >
              <EmployeeProfileDetails
                user={user}
                employee={employee}
                setEmployee={setEmployee}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default EmployeeView;
