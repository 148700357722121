import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid
} from '@material-ui/core';
import { FilePlus as PlusIcon } from 'react-feather';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from 'src/constants/api';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, user, room, setRoom, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Formik
          initialValues={{
            name: ''
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255)
              .required(getLocaleString('nameRequired', user.language))
          })}
          onSubmit={(values, actions) => {
            axios
              .post(`${BASE_URL}/element`, {
                roomId: room.id,
                name: values.name
              })
              .then(response => {
                toast.success(getLocaleString('elementCreated', user.language));
                const newRoom = { ...room };
                newRoom.elements = [...newRoom.elements, response.data];
                setRoom(newRoom);
                actions.setSubmitting(false);
                actions.resetForm();
              })
              .catch(err => {
                toast.error(err.message);
                actions.setSubmitting(false);
              });
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            values
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={9} md={9} xs={12}>
                      <Box>
                        <TextField
                          fullWidth
                          name="name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                  <PlusIcon />
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          placeholder={getLocaleString('name', user.language)}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        style={{ height: '100%' }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={isSubmitting || !isValid}
                          style={{ width: '100%' }}
                          type="submit"
                        >
                          {getLocaleString('addElement', user.language)}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
