import React from 'react';
import { Box, Container } from '@material-ui/core';
import UsersList from './UsersList';

const UsersListView = ({ user, company }) => {
  return (
    <Container style={{ padding: 0 }}>
      <Box mt={3}>
        <UsersList user={user} company={company} />
      </Box>
    </Container>
  );
};

export default UsersListView;
