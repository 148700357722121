import React from 'react';
import { makeStyles } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(() => ({
  container: {
    fontFamily: 'Arial',
    textAlign: 'center',
    fontSize: 25,
    position: 'absolute',
    margin: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  contactIcon: {
    position: 'absolute',
    top: 0
  },
  icon: {
    fontSize: 200,
    color: '#3f51b5'
  }
}));

const Contact = () => {
  const classes = useStyles();
  let userLang = navigator.language || navigator.userLanguage;

  if (userLang.length > 2) {
    userLang = userLang.substring(0, 2);
  }

  return (
    <div className={classes.container}>
      <h1>Contact</h1>
      <p>
        {getLocaleString('contact1', userLang)} <b>+32483181708</b>{' '}
        {getLocaleString('contact2', userLang)} <b>info@inspectorpro.be</b>
      </p>

      <QuestionAnswerIcon className={classes.icon} />
    </div>
  );
};

export default Contact;
