import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomersVisited } from 'src/redux/customerSlice';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';
import { getSheetData, getColumnLetter } from 'src/utils/excelUtils';
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UserListView = ({ user }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const visitedCustomers = useSelector(
    state => state.customer.visitedCustomers
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const year = selectedDate.getFullYear();
    if (!(year in visitedCustomers)) {
      dispatch(fetchCustomersVisited(year));
    }
  }, [selectedDate]);

  const saveAsExcel = async () => {
    const year = selectedDate.getFullYear();
    if (visitedCustomers[year].length > 0) {
      const header = [
        'ID',
        getLocaleString('customer', user.language),
        getLocaleString('building', user.language),
        getLocaleString('email', user.language),
        getLocaleString('phone', user.language),
        getLocaleString('inspectionCount', user.language),
        getLocaleString('averageScore', user.language),
        getLocaleString('latestInspection', user.language)
      ];

      XlsxPopulate.fromBlankAsync().then(async workbook => {
        const sheet1 = workbook
          .sheet(0)
          .name(
            `${getLocaleString('visitedCustomers', user.language)} ${year}`
          );

        const sortedVC = visitedCustomers.map(vc => ({
          id: vc.id,
          customer: vc.customer,
          building: vc.building,
          contacts: vc.contacts,
          phone: vc.phone,
          inspectionCount: vc.inspectionCount,
          averageScore: vc.averageScore,
          latestInspection: new Date(vc.latestInspection)
            .toLocaleString()
            .split(',')[0]
        }));

        const sheetData = getSheetData(sortedVC, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell('A1').value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = getColumnLetter(totalColumns - 1);
        sheet1.row(1).style('bold', true);
        sheet1.range(`A1:${endColumn}1`).style('fill', 'BFBFBF');
        range.style('border', true);
        sheet1.column('A').width(15);
        sheet1.column('B').width(33);
        sheet1.column('C').width(60);
        sheet1.column('D').width(60);
        sheet1.column('E').width(15);
        sheet1.column('F').width(21);
        sheet1.column('G').width(14);
        sheet1.column('H').width(22);
        return workbook.outputAsync().then(res => {
          saveAs(
            res,
            `${getLocaleString(
              'visitedCustomersFileName',
              user.language
            )}${year}.xlsx`
          );
        });
      });
    }
  };

  return (
    <Page
      className={classes.root}
      title={getLocaleString('users', user.language)}
    >
      <Container maxWidth={false}>
        {!visitedCustomers[selectedDate.getFullYear()] ? (
          <Loader small />
        ) : (
          <>
            <Box mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <DatePicker
                    showYearPicker
                    dateFormat="yyyy"
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    minDate={new Date('2021/01/01')}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={saveAsExcel}
                    >
                      {getLocaleString('download', user.language)}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mt={3}>
              <TableContainer component={Paper} id="visitedTableContainer">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={10}>ID</TableCell>
                      <TableCell align="right">
                        {getLocaleString('customer', user.language)}
                      </TableCell>
                      <TableCell align="right">
                        {getLocaleString('building', user.language)}
                      </TableCell>
                      <TableCell align="right">
                        {getLocaleString('contacts', user.language)}
                      </TableCell>
                      <TableCell align="right">
                        {getLocaleString('phone', user.language)}
                      </TableCell>
                      <TableCell align="right">
                        {getLocaleString('inspectionCount', user.language)}
                      </TableCell>
                      <TableCell align="right">
                        {getLocaleString('averageScore', user.language)}
                      </TableCell>
                      <TableCell align="right">
                        {getLocaleString('latestInspection', user.language)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visitedCustomers[selectedDate.getFullYear()].map(vc => (
                      <TableRow
                        key={vc.building}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          navigate(`/app/customers/${vc.customerId}`)
                        }
                      >
                        <TableCell align="center">{vc.id}</TableCell>
                        <TableCell align="right">{vc.customer}</TableCell>
                        <TableCell align="right">{vc.building}</TableCell>
                        <TableCell align="right">{vc.contacts}</TableCell>
                        <TableCell align="right">{vc.phone}</TableCell>
                        <TableCell align="right">
                          {vc.inspectionCount}
                        </TableCell>
                        <TableCell align="right">{vc.averageScore}</TableCell>
                        <TableCell align="right">
                          {
                            new Date(vc.latestInspection)
                              .toLocaleString()
                              .split(',')[0]
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default UserListView;
