import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import 'src/styles/mainStyles.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
