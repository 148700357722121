import React, { useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStats, fetchThreshold } from 'src/redux/statisticSlice';
import Page from 'src/components/Page';
import InspectionYearGraph from 'src/components/InspectionYearGraph';
import getLocaleString from 'src/utils/getLocaleString';
import Threshold from './Threshold';
import LatestInspections from './LatestInspections';
import CustomerSatisfaction from './CustomerSatisfaction';
import TotalCustomers from './TotalCustomers';
import TotalInspections from './TotalInspections';
import InspectorStats from './InspectorStats';
import Loader from 'src/components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = ({ user }) => {
  const classes = useStyles();
  const threshold = useSelector(state => state.statistic.threshold);
  const stats = useSelector(state => state.statistic.stats);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stats === null) {
      dispatch(fetchStats());
    }

    if (threshold === null) {
      dispatch(fetchThreshold());
    }
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        {stats !== null && threshold !== null ? (
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Threshold threshold={threshold} user={user} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCustomers customerCount={stats.customerCount} user={user} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CustomerSatisfaction
                threshold={threshold}
                satisfaction={stats.averageSatisfaction}
                user={user}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalInspections
                inspectionCount={stats.inspectionCount}
                user={user}
              />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <InspectionYearGraph
                stats={stats.satisfactionGraphStats}
                title={getLocaleString('averageInspectionScore', user.language)}
                user={user}
              />
            </Grid>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <InspectorStats stats={stats.inspectedPercentage} user={user} />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <LatestInspections
                inspections={stats.latestInspections}
                threshold={threshold}
                user={user}
              />
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
      </Container>
    </Page>
  );
};

export default Dashboard;
