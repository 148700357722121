import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import axios from 'axios';
import { BASE_URL } from '../../../constants/api';
import locales from '../../../constants/locales';
import getLocaleString from '../../../utils/getLocaleString';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const Language = ({ className, user, setUser, ...rest }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        locale: user.language
      }}
      onSubmit={(values, actions) => {
        axios
          .put(`${BASE_URL}/user/language`, values)
          .then(response => {
            setUser(response.data);
            actions.setSubmitting(false);
          })
          .catch(err => {
            if (err.response) {
              toast.error(err.response.data.message);
            } else {
              toast.error(err.message);
            }
            actions.setSubmitting(false);
          });
      }}
    >
      {({ handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={getLocaleString('changeLanguageSub', user.language)}
              title={getLocaleString('language', user.language)}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label={getLocaleString('selectLanguage', user.language)}
                    name="locale"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.locale}
                    variant="outlined"
                  >
                    {locales.map(locale => (
                      <option key={locale.code} value={locale.code}>
                        {locale.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting || values.locale === user.language}
                type="submit"
              >
                {getLocaleString('save', user.language)}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

Language.propTypes = {
  className: PropTypes.string
};

export default Language;
