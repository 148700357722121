import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import Calendar from './Calendar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ScheduleView = ({ user }) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Schedule">
      <Container>
        <Box>
          <Calendar user={user} />
        </Box>
      </Container>
    </Page>
  );
};

export default ScheduleView;
