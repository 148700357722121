import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import getLocaleString from 'src/utils/getLocaleString';
import { getRole, hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, user, users, search, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = id => {
    if (hasWriteRight(user)) {
      navigate(`/app/users/${id}`, { replace: true });
    }
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 88 }} />
                <TableCell>{getLocaleString('name', user.language)}</TableCell>
                <TableCell>{getLocaleString('email', user.language)}</TableCell>
                <TableCell>{getLocaleString('phone', user.language)}</TableCell>
                <TableCell>{getLocaleString('role', user.language)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .filter(u =>
                  u.name.toLowerCase().includes(search.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(listUser => (
                  <TableRow
                    hover
                    style={hasWriteRight(user) ? { cursor: 'pointer' } : {}}
                    key={listUser.id}
                    onClick={() => handleRowClick(listUser.id)}
                  >
                    <TableCell>
                      <Avatar
                        className={classes.avatar}
                        src={listUser.imageUrl}
                      >
                        {getInitials(listUser.name)}
                      </Avatar>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {listUser.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{listUser.email}</TableCell>
                    <TableCell>{listUser.phone}</TableCell>
                    <TableCell>{getRole(listUser, user.language)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={
          users.filter(u => u.name.toLowerCase().includes(search.toLowerCase()))
            .length
        }
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default Results;
