import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  Input,
  MenuItem,
  ListItemText,
  Chip,
  CircularProgress
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { enUS, nlBE, fr } from 'date-fns/locale';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchEvents,
  addEvent,
  updateEvent,
  deleteEvent
} from 'src/redux/eventSlice';
import { fetchUsers } from 'src/redux/userSlice';
import { fetchCustomers } from 'src/redux/customerSlice';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';
import Loader from 'src/components/Loader';
import 'src/styles/calendar.css';
import ExceptionForm from './ExceptionForm';

const useStyles = makeStyles(theme => ({
  root: {},
  calendar: {
    fontFamily: 'Arial',
    backgroundColor: 'white'
  },
  formControl: {},
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Calendar = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [yupSchemaId, setYupSchemaId] = useState('');
  const [yupSchema, setYupSchema] = useState(null);
  const [eventToUpdate, setEventToUpdate] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUniqueUpdate, setIsUniqueUpdate] = useState(null);
  const [viewYears, setViewYears] = useState([null, null]);
  const [initializationDone, setInitializationDone] = useState(false);
  const [showExceptions, setShowExceptions] = useState(false);
  const [exceptionToUpdate, setExceptionToUpdate] = useState(null);
  const events = useSelector(state => state.event.events);
  const users = useSelector(state => state.user.users);
  const customers = useSelector(state => state.customer.customers);
  const fetchedYears = useSelector(state => state.event.fetchedYears);
  const dispatch = useDispatch();
  const calendarRef = useRef();

  const formikRef = useRef();

  const weekdays = [
    getLocaleString('monday', user.language),
    getLocaleString('tuesday', user.language),
    getLocaleString('wednesday', user.language),
    getLocaleString('thursday', user.language),
    getLocaleString('friday', user.language),
    getLocaleString('saturday', user.language),
    getLocaleString('sunday', user.language)
  ];

  const adminUniqueSchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    userId: Yup.number()
      .typeError(getLocaleString('inspectorRequired', user.language))
      .required(getLocaleString('inspectorRequired', user.language))
      .moreThan(0, getLocaleString('inspectorRequired', user.language)),
    date: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      )
  });

  const adminRepeatInfiniteDailySchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    userId: Yup.number()
      .typeError(getLocaleString('inspectorRequired', user.language))
      .required(getLocaleString('inspectorRequired', user.language))
      .moreThan(0, getLocaleString('inspectorRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    ),
    weekDays: Yup.array().required(
      getLocaleString('weekdayRequired', user.language)
    )
  });

  const adminRepeatInfiniteSchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    userId: Yup.number()
      .typeError(getLocaleString('inspectorRequired', user.language))
      .required(getLocaleString('inspectorRequired', user.language))
      .moreThan(0, getLocaleString('inspectorRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    )
  });

  const adminRepeatDailySchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    userId: Yup.number()
      .typeError(getLocaleString('inspectorRequired', user.language))
      .required(getLocaleString('inspectorRequired', user.language))
      .moreThan(0, getLocaleString('inspectorRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    endDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    ),
    weekDays: Yup.array().required(
      getLocaleString('weekdayRequired', user.language)
    )
  });

  const adminRepeatSchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    userId: Yup.number()
      .typeError(getLocaleString('inspectorRequired', user.language))
      .required(getLocaleString('inspectorRequired', user.language))
      .moreThan(0, getLocaleString('inspectorRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    endDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    )
  });

  const inspectorUniqueSchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    date: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      )
  });

  const inspectorRepeatInfiniteDailySchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    ),
    weekDays: Yup.array().required(
      getLocaleString('weekdayRequired', user.language)
    )
  });

  const inspectorRepeatInfiniteSchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    )
  });

  const inspectorRepeatDailySchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    endDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    ),
    weekDays: Yup.array().required(
      getLocaleString('weekdayRequired', user.language)
    )
  });

  const inspectorRepeatSchema = Yup.object().shape({
    customerId: Yup.number()
      .typeError(getLocaleString('customerRequired', user.language))
      .required(getLocaleString('customerRequired', user.language))
      .moreThan(0, getLocaleString('customerRequired', user.language)),
    buildingId: Yup.number()
      .typeError(getLocaleString('buildingRequired', user.language))
      .required(getLocaleString('buildingRequired', user.language))
      .moreThan(0, getLocaleString('buildingRequired', user.language)),
    startDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    endDate: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    start: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    end: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'start',
        (start, schema) =>
          start &&
          schema.min(start, getLocaleString('endTimeAfterStart', user.language))
      ),
    repeatType: Yup.string().required(
      getLocaleString('repeatTypeRequired', user.language)
    )
  });

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.getEventSources().forEach(es => es.remove());
    calendarApi.addEventSource(events);
  }, [calendarRef, events]);

  const checkYearEvents = years => {
    years.forEach(year => {
      if (!fetchedYears.includes(year)) {
        dispatch(fetchEvents(year));
      }
    });
  };

  const handleSchemaChange = (id, schema) => {
    if (yupSchemaId !== id) {
      setYupSchemaId(id);
      setYupSchema(schema);
    }
  };

  const generateYupSchema = values => {
    if (hasWriteRight(user)) {
      if (values.eventType === 'unique') {
        handleSchemaChange('adminUniqueSchema', adminUniqueSchema);
      } else {
        if (values.infinite) {
          if (values.repeatType === 'DAILY') {
            handleSchemaChange(
              'adminRepeatInfiniteDailySchema',
              adminRepeatInfiniteDailySchema
            );
          } else {
            handleSchemaChange(
              'adminRepeatInfiniteSchema',
              adminRepeatInfiniteSchema
            );
          }
        } else {
          if (values.repeatType === 'DAILY') {
            handleSchemaChange(
              'adminRepeatDailySchema',
              adminRepeatDailySchema
            );
          } else {
            handleSchemaChange('adminRepeatSchema', adminRepeatSchema);
          }
        }
      }
    } else {
      if (values.eventType === 'unique') {
        handleSchemaChange('inspectorUniqueSchema', inspectorUniqueSchema);
      } else {
        if (values.infinite) {
          if (values.repeatType === 'DAILY') {
            handleSchemaChange(
              'inspectorRepeatInfiniteDailySchema',
              inspectorRepeatInfiniteDailySchema
            );
          } else {
            handleSchemaChange(
              'inspectorRepeatInfiniteSchema',
              inspectorRepeatInfiniteSchema
            );
          }
        } else {
          if (values.repeatType === 'DAILY') {
            handleSchemaChange(
              'inspectorRepeatDailySchema',
              inspectorRepeatDailySchema
            );
          } else {
            handleSchemaChange('inspectorRepeatSchema', inspectorRepeatSchema);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (users === null) {
      dispatch(fetchUsers());
    }
    if (customers === null) {
      dispatch(fetchCustomers());
    }
  }, []);

  useEffect(() => {
    let prev = null;
    const years = [];
    viewYears.forEach(year => {
      if (year && prev !== year) {
        prev = year;
        years.push(year);
      }
    });

    if (years.length > 0) {
      checkYearEvents(years);
    }
  }, [viewYears]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEventToUpdate(null);
    setIsUpdate(false);
    setIsUniqueUpdate(null);
    setDialogOpen(false);
    setInitializationDone(false);
    setShowExceptions(false);
    setExceptionToUpdate(null);
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const handleEventClick = e => {
    let url;
    setIsUpdate(true);
    setDialogOpen(true);
    if (e.event._def.publicId.includes('-')) {
      setIsUniqueUpdate(false);
      url = `${BASE_URL}/repeating-event/${
        e.event._def.publicId.split('-')[0]
      }`;
    } else {
      setIsUniqueUpdate(true);
      url = `${BASE_URL}/unique-event/${e.event._def.publicId}`;
    }

    axios
      .get(url)
      .then(response => {
        setEventToUpdate(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleEventDelete = () => {
    let url;
    if (isUniqueUpdate) {
      url = `${BASE_URL}/unique-event/${eventToUpdate.id}`;
    } else {
      url = `${BASE_URL}/repeating-event/${eventToUpdate.id}`;
    }

    axios
      .delete(url)
      .then(() => {
        dispatch(deleteEvent(eventToUpdate.id));
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
    handleDialogClose();
  };

  const getLocale = () => {
    switch (user.language) {
      case 'fr':
        return fr;
      case 'nl':
        return nlBE;
      default:
        return enUS;
    }
  };

  const getInitialEndTime = () => {
    const d = new Date();
    d.setTime(d.getTime() + 60 * 60 * 1000);
    return d;
  };

  const getInitialEndDate = () => {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    return d;
  };

  const isReadOnly = () => {
    return (
      isUpdate && !hasWriteRight(user) && eventToUpdate.inspectorId !== user.id
    );
  };

  const getAspectRatio = () => {
    const screenWidth = window.screen.width;
    if (screenWidth > 1800) {
      return 1.8;
    }
    if (screenWidth > 1200) {
      return 1.6;
    }
    if (screenWidth > 1000) {
      return 1.4;
    }
    if (screenWidth > 700) {
      return 1.3;
    }
    if (screenWidth > 600) {
      return 1.2;
    }
    if (screenWidth > 500) {
      return 1.1;
    }
    if (screenWidth > 450) {
      return 1;
    }
    return 0.9;
  };

  const FormManager = () => {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      generateYupSchema(values);
    }, [values.infinite, values.eventType, values.repeatType]);

    useEffect(() => {
      if (
        isUpdate &&
        eventToUpdate &&
        isUniqueUpdate !== null &&
        !initializationDone
      ) {
        setInitializationDone(true);
        setFieldValue('customerId', eventToUpdate.customerId);
        setFieldValue('buildingId', eventToUpdate.buildingId);
        setFieldValue('userId', eventToUpdate.inspectorId);
        if (isUniqueUpdate) {
          setFieldValue('eventType', 'unique');
          setFieldValue('date', new Date(eventToUpdate.startDate));
          setFieldValue('start', new Date(eventToUpdate.startDate));
          setFieldValue('end', new Date(eventToUpdate.endDate));
        } else {
          setFieldValue('eventType', 'repeat');
          setFieldValue('startDate', new Date(eventToUpdate.startDate));
          if (eventToUpdate.endDate) {
            setFieldValue('endDate', new Date(eventToUpdate.endDate));
          } else {
            setFieldValue('infinite', true);
          }
          setFieldValue('start', new Date(eventToUpdate.startTime));
          setFieldValue('end', new Date(eventToUpdate.endTime));
          setFieldValue('repeatType', eventToUpdate.repeatType);
          if (eventToUpdate.weekDays) {
            setFieldValue(
              'weekDays',
              eventToUpdate.weekDays.split(',').map(x => {
                return parseInt(x, 10);
              })
            );
          }
        }
      }
    }, [isUpdate, eventToUpdate, isUniqueUpdate, initializationDone]);
    return null;
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box>
        <Card>
          <CardContent>
            <div className={classes.calendar}>
              <FullCalendar
                ref={calendarRef}
                locale={user.language}
                customButtons={{
                  newEvent: {
                    text: getLocaleString('new', user.language),
                    click: handleDialogOpen
                  }
                }}
                headerToolbar={{
                  start: 'prevYear prev,today,next nextYear',
                  center: 'title',
                  end: 'dayGridMonth,timeGridWeek,listWeek newEvent'
                }}
                slotLabelFormat={date => {
                  let hour = date.date.hour.toString();
                  let minute = date.date.minute.toString();

                  if (hour.length < 2) {
                    hour = `0${hour}`;
                  }

                  if (minute.length < 2) {
                    minute = `0${minute}`;
                  }

                  return `${hour}:${minute}`;
                }}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hourCycle: 'h23'
                }}
                firstDay={1}
                slotMaxTime="23:59:59"
                allDaySlot={false}
                aspectRatio={getAspectRatio()}
                editable={false}
                selectable
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                initialView="dayGridMonth"
                buttonText={{
                  today: getLocaleString('today', user.language),
                  month: getLocaleString('month', user.language),
                  week: getLocaleString('week', user.language),
                  day: getLocaleString('day', user.language),
                  list: getLocaleString('list', user.language)
                }}
                timeZone="CET"
                eventClick={handleEventClick}
                datesSet={arg => {
                  setViewYears([
                    arg.start.getFullYear(),
                    arg.end.getFullYear()
                  ]);
                }}
              />
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={{
                date: new Date(),
                start: new Date(),
                end: getInitialEndTime(),
                customerId: 0,
                buildingId: 0,
                userId: 0,
                startDate: new Date(),
                endDate: getInitialEndDate(),
                infinite: false,
                eventType: 'unique',
                repeatType: '',
                weekDays: []
              }}
              validationSchema={yupSchema}
              onSubmit={(values, actions) => {
                const event = {
                  buildingId: values.buildingId,
                  customerId: values.customerId
                };

                let baseEndPoint;

                if (values.eventType === 'unique') {
                  baseEndPoint = `${BASE_URL}/unique-event`;
                  const startDate = new Date(values.date);
                  const endDate = new Date(values.date);
                  startDate.setHours(
                    values.start.getHours(),
                    values.start.getMinutes()
                  );

                  endDate.setHours(
                    values.end.getHours(),
                    values.end.getMinutes()
                  );

                  event.startDate = moment(startDate).format(
                    'YYYY-MM-DD HH:mm:SS'
                  );
                  event.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:SS');
                } else {
                  baseEndPoint = `${BASE_URL}/repeating-event`;

                  event.startDate = moment(new Date(values.startDate)).format(
                    'YYYY-MM-DD HH:mm:SS'
                  );

                  if (!values.infinite) {
                    event.endDate = moment(new Date(values.endDate)).format(
                      'YYYY-MM-DD HH:mm:SS'
                    );
                  }

                  event.startTime = moment(new Date(values.start)).format(
                    'YYYY-MM-DD HH:mm:SS'
                  );

                  event.endTime = moment(new Date(values.end)).format(
                    'YYYY-MM-DD HH:mm:SS'
                  );

                  event.repeatType = values.repeatType;

                  if (values.repeatType === 'DAILY') {
                    event.selectedWeekdays = values.weekDays.join();
                  }
                }

                if (hasWriteRight(user)) {
                  event.inspectorId = values.userId;
                } else {
                  event.inspectorId = user.id;
                }

                if (isUpdate) {
                  axios
                    .put(`${baseEndPoint}/${eventToUpdate.id}`, event)
                    .then(response => {
                      dispatch(updateEvent(response.data));
                      handleDialogClose();
                      actions.setSubmitting(false);
                    })
                    .catch(err => {
                      if (err.response) {
                        toast.error(err.response.data.message);
                      } else {
                        toast.error(err.message);
                      }
                      actions.setSubmitting(false);
                    });
                } else {
                  axios
                    .post(`${baseEndPoint}`, event)
                    .then(response => {
                      dispatch(addEvent(response.data));
                      handleDialogClose();
                      actions.setSubmitting(false);
                    })
                    .catch(err => {
                      if (err.response) {
                        toast.error(err.response.data.message);
                      } else {
                        toast.error(err.message);
                      }
                      actions.setSubmitting(false);
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                isValid,
                values,
                setFieldValue
              }) => (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  className={clsx(classes.root, className)}
                  {...rest}
                >
                  <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {isUpdate
                        ? showExceptions
                          ? exceptionToUpdate
                            ? exceptionToUpdate.id === 0
                              ? getLocaleString(
                                  'createException',
                                  user.language
                                )
                              : getLocaleString('editException', user.language)
                            : getLocaleString('exceptions', user.language)
                          : getLocaleString('updateEventTitle', user.language)
                        : getLocaleString('createEventTitle', user.language)}
                    </DialogTitle>
                    {isUpdate && !eventToUpdate ? (
                      <Loader small style={{ padding: 'none', margin: 50 }} />
                    ) : (
                      <>
                        <DialogContent style={{ overflow: 'hidden' }}>
                          <Grid container spacing={3}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={getLocale()}
                            >
                              {!showExceptions && customers ? (
                                <>
                                  <Grid item md={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      error={Boolean(
                                        touched.customerId && errors.customerId
                                      )}
                                      helperText={
                                        touched.customerId && errors.customerId
                                      }
                                      label={getLocaleString(
                                        'selectCustomer',
                                        user.language
                                      )}
                                      name="customerId"
                                      onChange={e => {
                                        handleChange(e);
                                        setFieldValue('buildingId', 0);
                                      }}
                                      onBlur={handleBlur}
                                      required
                                      select
                                      disabled={
                                        isReadOnly() ||
                                        (isUpdate && eventToUpdate.completed)
                                      }
                                      SelectProps={{ native: true }}
                                      value={values.customerId}
                                      variant="outlined"
                                    >
                                      <option value={0}>
                                        {getLocaleString(
                                          'selectCustomerPlaceholder',
                                          user.language
                                        )}
                                      </option>
                                      {customers.map(customer => (
                                        <option
                                          key={customer.id}
                                          value={customer.id}
                                        >
                                          {customer.name}
                                        </option>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      error={Boolean(
                                        touched.buildingId && errors.buildingId
                                      )}
                                      helperText={
                                        touched.buildingId && errors.buildingId
                                      }
                                      label={getLocaleString(
                                        'selectBuilding',
                                        user.language
                                      )}
                                      name="buildingId"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                      select
                                      disabled={
                                        isReadOnly() ||
                                        Number(values.customerId) === 0 ||
                                        (isUpdate && eventToUpdate.completed)
                                      }
                                      SelectProps={{ native: true }}
                                      value={values.buildingId}
                                      variant="outlined"
                                    >
                                      <option value={0}>
                                        {getLocaleString(
                                          'selectBuildingPlaceholder',
                                          user.language
                                        )}
                                      </option>
                                      {Number(values.customerId) !== 0 ? (
                                        customers
                                          .find(
                                            c =>
                                              c.id === Number(values.customerId)
                                          )
                                          .buildings.map(building => (
                                            <option
                                              key={building.id}
                                              value={building.id}
                                            >
                                              {building.name}
                                            </option>
                                          ))
                                      ) : (
                                        <></>
                                      )}
                                    </TextField>
                                  </Grid>
                                  {user.role !== 'INSPECTOR' ? (
                                    <Grid item xs={12}>
                                      {users ? (
                                        <TextField
                                          fullWidth
                                          error={Boolean(
                                            touched.userId && errors.userId
                                          )}
                                          helperText={
                                            touched.userId && errors.userId
                                          }
                                          label={getLocaleString(
                                            'selectInspector',
                                            user.language
                                          )}
                                          name="userId"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          required
                                          select
                                          disabled={
                                            isReadOnly() ||
                                            (isUpdate &&
                                              eventToUpdate.completed)
                                          }
                                          SelectProps={{ native: true }}
                                          value={values.userId}
                                          variant="outlined"
                                        >
                                          {users.map(u => (
                                            <option key={u.id} value={u.id}>
                                              {u.name}
                                            </option>
                                          ))}
                                        </TextField>
                                      ) : (
                                        <CircularProgress />
                                      )}
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                  {values.eventType === 'unique' ? (
                                    <>
                                      <Grid item xs={12}>
                                        <DatePicker
                                          fullWidth
                                          error={Boolean(
                                            touched.date && errors.date
                                          )}
                                          helperText={
                                            touched.date && errors.date
                                          }
                                          variant="inline"
                                          format="dd/MM/yyyy"
                                          margin="normal"
                                          name="date"
                                          id="date"
                                          readOnly={
                                            isReadOnly() ||
                                            (isUpdate &&
                                              eventToUpdate.completed)
                                          }
                                          InputProps={
                                            isReadOnly() ||
                                            (isUpdate &&
                                              eventToUpdate.completed)
                                              ? { readOnly: true }
                                              : {}
                                          }
                                          label={getLocaleString(
                                            'date',
                                            user.language
                                          )}
                                          value={values.date}
                                          onBlur={handleBlur}
                                          onChange={val => {
                                            setFieldValue('date', val);
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid item xs={12}>
                                        <TextField
                                          fullWidth
                                          error={Boolean(
                                            touched.repeatType &&
                                              errors.repeatType
                                          )}
                                          helperText={
                                            touched.repeatType &&
                                            errors.repeatType
                                          }
                                          label={getLocaleString(
                                            'repeatType',
                                            user.language
                                          )}
                                          name="repeatType"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          required
                                          select
                                          disabled={isReadOnly()}
                                          SelectProps={{ native: true }}
                                          value={values.repeatType}
                                          variant="outlined"
                                        >
                                          <option value={null}>
                                            {getLocaleString(
                                              'selectRepeatTypePlaceholder',
                                              user.language
                                            )}
                                          </option>
                                          <option value="DAILY">
                                            {getLocaleString(
                                              'daily',
                                              user.language
                                            )}
                                          </option>
                                          <option value="WEEKLY">
                                            {getLocaleString(
                                              'weekly',
                                              user.language
                                            )}
                                          </option>
                                          <option value="MONTHLY">
                                            {getLocaleString(
                                              'monthly',
                                              user.language
                                            )}
                                          </option>
                                          <option value="YEARLY">
                                            {getLocaleString(
                                              'yearly',
                                              user.language
                                            )}
                                          </option>
                                        </TextField>
                                      </Grid>
                                      {values.repeatType === 'DAILY' ? (
                                        <Grid item xs={12}>
                                          <FormControl
                                            fullWidth
                                            error={Boolean(
                                              touched.weekDays &&
                                                errors.weekDays
                                            )}
                                          >
                                            <InputLabel id="weekDaysLabel">
                                              {getLocaleString(
                                                'weekdays',
                                                user.language
                                              )}
                                            </InputLabel>
                                            <Select
                                              fullWidth
                                              labelId="weekDaysLabel"
                                              id="weekDays"
                                              name="weekDays"
                                              multiple
                                              value={values.weekDays}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              input={
                                                <Input id="weekDaysSelect" />
                                              }
                                              renderValue={selected => {
                                                return (
                                                  <div
                                                    className={classes.chips}
                                                  >
                                                    {selected.map(value => (
                                                      <Chip
                                                        key={value}
                                                        label={
                                                          weekdays[value - 1]
                                                        }
                                                        className={classes.chip}
                                                      />
                                                    ))}
                                                  </div>
                                                );
                                              }}
                                              MenuProps={MenuProps}
                                            >
                                              {weekdays.map(
                                                (weekday, index) => (
                                                  <MenuItem
                                                    key={weekday}
                                                    value={index + 1}
                                                  >
                                                    <Checkbox
                                                      checked={
                                                        values.weekDays.indexOf(
                                                          index + 1
                                                        ) > -1
                                                      }
                                                    />
                                                    <ListItemText
                                                      primary={weekday}
                                                    />
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                            <FormHelperText>
                                              {touched.weekDays &&
                                                errors.weekDays}
                                            </FormHelperText>
                                          </FormControl>
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}
                                      <Grid item xs={12}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="infinite"
                                              id="infinite"
                                              checked={values.infinite}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              color="primary"
                                            />
                                          }
                                          label={getLocaleString(
                                            'infiniteEvent',
                                            user.language
                                          )}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={values.infinite ? 12 : 6}
                                        xs={12}
                                      >
                                        <DatePicker
                                          fullWidth
                                          error={Boolean(
                                            touched.startDate &&
                                              errors.startDate
                                          )}
                                          helperText={
                                            touched.startDate &&
                                            errors.startDate
                                          }
                                          variant="inline"
                                          format="dd/MM/yyyy"
                                          margin="normal"
                                          name="startDate"
                                          id="startDate"
                                          readOnly={
                                            isReadOnly() ||
                                            (isUpdate &&
                                              eventToUpdate.completed)
                                          }
                                          InputProps={
                                            isReadOnly()
                                              ? { readOnly: true }
                                              : {}
                                          }
                                          label={getLocaleString(
                                            'startDate',
                                            user.language
                                          )}
                                          value={values.startDate}
                                          onBlur={handleBlur}
                                          onChange={val => {
                                            setFieldValue('startDate', val);
                                          }}
                                        />
                                      </Grid>
                                      {!values.infinite ? (
                                        <Grid item md={6} xs={12}>
                                          <DatePicker
                                            fullWidth
                                            error={Boolean(
                                              touched.endDate && errors.endDate
                                            )}
                                            helperText={
                                              touched.endDate && errors.endDate
                                            }
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            name="endDate"
                                            id="endDate"
                                            readOnly={
                                              isReadOnly() ||
                                              (isUpdate &&
                                                eventToUpdate.completed)
                                            }
                                            InputProps={
                                              isReadOnly()
                                                ? { readOnly: true }
                                                : {}
                                            }
                                            label={getLocaleString(
                                              'endDate',
                                              user.language
                                            )}
                                            value={values.endDate}
                                            onBlur={handleBlur}
                                            onChange={val => {
                                              setFieldValue('endDate', val);
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <Grid item md={6} xs={12}>
                                    <TimePicker
                                      fullWidth
                                      error={Boolean(
                                        touched.start && errors.start
                                      )}
                                      helperText={touched.start && errors.start}
                                      ampm={false}
                                      label={getLocaleString(
                                        'startTime',
                                        user.language
                                      )}
                                      margin="normal"
                                      name="start"
                                      id="start"
                                      value={values.start}
                                      onBlur={handleBlur}
                                      readOnly={
                                        isReadOnly() ||
                                        (isUpdate && eventToUpdate.completed)
                                      }
                                      InputProps={
                                        isReadOnly() ? { readOnly: true } : {}
                                      }
                                      onChange={val => {
                                        setFieldValue('start', val);
                                      }}
                                      cancelLabel={getLocaleString(
                                        'cancel',
                                        user.language
                                      )}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <TimePicker
                                      fullWidth
                                      error={Boolean(touched.end && errors.end)}
                                      helperText={touched.end && errors.end}
                                      ampm={false}
                                      label={getLocaleString(
                                        'endTime',
                                        user.language
                                      )}
                                      margin="normal"
                                      name="end"
                                      id="end"
                                      value={values.end}
                                      readOnly={
                                        isReadOnly() ||
                                        (isUpdate && eventToUpdate.completed)
                                      }
                                      InputProps={
                                        isReadOnly() ? { readOnly: true } : {}
                                      }
                                      onBlur={handleBlur}
                                      onChange={val => {
                                        setFieldValue('end', val);
                                      }}
                                      cancelLabel={getLocaleString(
                                        'cancel',
                                        user.language
                                      )}
                                    />
                                  </Grid>
                                </>
                              ) : (
                                <Grid item xs={12}>
                                  <ExceptionForm
                                    user={user}
                                    eventToUpdate={eventToUpdate}
                                    setEventToUpdate={setEventToUpdate}
                                    exceptionToUpdate={exceptionToUpdate}
                                    setExceptionToUpdate={setExceptionToUpdate}
                                    getInitialEndTime={getInitialEndTime}
                                    isReadOnly={isReadOnly}
                                  />
                                </Grid>
                              )}
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </DialogContent>
                        {!showExceptions ? (
                          <>
                            {!isReadOnly() &&
                            !(isUpdate && eventToUpdate.completed) ? (
                              <DialogActions>
                                {isUpdate ? (
                                  <Button
                                    onClick={handleEventDelete}
                                    color="primary"
                                    style={{
                                      color: '#d11a2a',
                                      position: 'absolute',
                                      left: 10
                                    }}
                                  >
                                    {getLocaleString('delete', user.language)}
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                <Button
                                  onClick={handleDialogClose}
                                  color="primary"
                                >
                                  {getLocaleString('cancel', user.language)}
                                </Button>
                                <Button
                                  type="submit"
                                  onClick={handleSubmit}
                                  color="primary"
                                  style={
                                    isSubmitting || !isValid
                                      ? { color: '#525252' }
                                      : { color: '#129c09' }
                                  }
                                  autoFocus
                                  disabled={isSubmitting || !isValid}
                                >
                                  {isUpdate
                                    ? getLocaleString('update', user.language)
                                    : getLocaleString('add', user.language)}
                                </Button>
                              </DialogActions>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {!exceptionToUpdate ? (
                              <DialogActions>
                                <Button
                                  onClick={() => setShowExceptions(false)}
                                  color="primary"
                                >
                                  {getLocaleString('return', user.language)}
                                </Button>
                              </DialogActions>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Dialog>

                  <FormManager />
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Calendar.propTypes = {
  className: PropTypes.string
};

export default Calendar;
