import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const BuildingsList = ({ className, user, customer, setCustomer, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [buildingToDelete, setBuildingToDelete] = useState({});

  const handleDialogOpen = building => {
    setBuildingToDelete(building);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleBuildingDelete = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/building/${buildingToDelete.id}`)
      .then(() => {
        const newCustomer = { ...customer };
        newCustomer.buildings = newCustomer.buildings.filter(
          building => building.id !== buildingToDelete.id
        );
        setCustomer(newCustomer);
        toast.success(getLocaleString('buildingDeleted', user.language));
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleRowClick = (event, buildingId) => {
    if (
      !event.target.classList.contains('MuiButton-label') &&
      !event.target.classList.contains('MuiButtonBase-root')
    ) {
      navigate(`/app/buildings/${buildingId}`);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={getLocaleString('buildings', user.language)} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          {!customer ? (
            <LinearProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">
                    {getLocaleString('name', user.language)}
                  </TableCell>
                  <TableCell align="center">
                    {getLocaleString('address', user.language)}
                  </TableCell>
                  <TableCell align="center">
                    {getLocaleString('floorCount', user.language)}
                  </TableCell>
                  {hasWriteRight(user) ? (
                    <TableCell align="center">
                      {getLocaleString('action', user.language)}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {customer.buildings.map(building => {
                  let customerIdstring = customer.id.toString();
                  let buildingIdstring = building.id.toString();

                  while (customerIdstring.length < 4) {
                    customerIdstring = '0' + customerIdstring;
                  }

                  while (buildingIdstring.length < 4) {
                    buildingIdstring = '0' + buildingIdstring;
                  }
                  const stringId = customerIdstring + buildingIdstring;

                  return (
                    <TableRow
                      hover
                      key={building.id}
                      style={{ cursor: 'pointer' }}
                      onClick={e => handleRowClick(e, building.id)}
                    >
                      <TableCell align="center">{stringId}</TableCell>
                      <TableCell align="center">{building.name}</TableCell>
                      <TableCell align="center">{building.address}</TableCell>
                      <TableCell align="center">
                        {building.floors.length}
                      </TableCell>
                      {hasWriteRight(user) ? (
                        <TableCell align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: '#d11a2a' }}
                            onClick={() => handleDialogOpen(building)}
                          >
                            {getLocaleString('delete', user.language)}
                          </Button>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`${buildingToDelete.name} ${getLocaleString(
                'deleteBuilding',
                user.language
              )} `}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {getLocaleString('deleteBuildingText', user.language)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {getLocaleString('cancel', user.language)}
              </Button>
              <Button
                onClick={handleBuildingDelete}
                color="primary"
                style={{ color: '#d11a2a' }}
              >
                {getLocaleString('delete', user.language)}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

BuildingsList.propTypes = {
  className: PropTypes.string
};

export default BuildingsList;
