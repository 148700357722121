import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const InspectorStats = ({ stats, className, user, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: stats.map(stat => stat.value),
        backgroundColor: stats.map(stat => stat.color),
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: stats.map(stat => stat.label)
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const getPercentage = label => {
    const sum = stats.map(stat => stat.value).reduce((a, b) => a + b, 0);
    if (sum === 0) {
      return sum;
    }
    const found = stats.find(stat => stat.label === label);
    return Math.round((found.value / sum) * 100);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={getLocaleString('inspectionsDone', user.language)} />
      <Divider />
      <CardContent>
        <Box height={300} position="relative">
          <Doughnut data={data} options={options} />
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          {stats.map(({ color, label }) => (
            <Box key={label} p={1} textAlign="center">
              <Typography color="textPrimary" variant="body1">
                {label}
              </Typography>
              <Typography style={{ color }} variant="h2">
                {getPercentage(label)}%
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

InspectorStats.propTypes = {
  className: PropTypes.string
};

export default InspectorStats;
