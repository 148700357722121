import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {}
}));

const RoomDetails = ({ user, room, setRoom, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteRoom = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/room/${room.id}`)
      .then(() => {
        toast.success(getLocaleString('roomDeleted', user.language));
        navigate(`/app/floors/${room.floor.id}`, { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        name: room.name,
        floorId: room.floor.id
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255)
          .required(getLocaleString('nameRequired', user.language))
      })}
      onSubmit={(values, actions) => {
        axios
          .put(`${BASE_URL}/room/${room.id}`, values)
          .then(response => {
            setRoom(response.data);
            toast.success(getLocaleString('roomUpdated', user.language));
            actions.setSubmitting(false);
          })
          .catch(err => {
            if (err.response) {
              toast.error(err.response.data.message);
            } else {
              toast.error(err.message);
            }
            actions.setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={
                hasWriteRight(user)
                  ? getLocaleString('editSub', user.language)
                  : ''
              }
              title={getLocaleString('roomInfo', user.language)}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={getLocaleString('name', user.language)}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            {hasWriteRight(user) ? (
              <>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    <Box display="flex" justifyContent="flex-start" p={2}>
                      <Button
                        style={{ backgroundColor: '#d11a2a' }}
                        color="primary"
                        variant="contained"
                        onClick={handleDialogOpen}
                      >
                        {getLocaleString('deleteRoom', user.language)}
                      </Button>
                      <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {getLocaleString('deleteRoom', user.language)}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {getLocaleString('deleteRoomText', user.language)}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleDialogClose} color="primary">
                            {getLocaleString('cancel', user.language)}
                          </Button>
                          <Button
                            onClick={handleDeleteRoom}
                            color="primary"
                            style={{ color: '#d11a2a' }}
                            autoFocus
                          >
                            {getLocaleString('delete', user.language)}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting || !isValid}
                      >
                        {getLocaleString('save', user.language)}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

RoomDetails.propTypes = {
  className: PropTypes.string
};

export default RoomDetails;
