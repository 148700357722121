import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  },
  colorPrimaryRed: {
    backgroundColor: '#e8d1d1'
  },
  barColorPrimaryRed: {
    backgroundColor: '#E53935'
  },
  colorPrimaryGreen: {
    backgroundColor: '#b1e0bc'
  },
  barColorPrimaryGreen: {
    backgroundColor: '#43A047'
  }
}));

const CustomerSatisfaction = ({
  satisfaction,
  threshold,
  className,
  user,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {getLocaleString('SATISFACTION', user.language)}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {+satisfaction.toFixed(2)}%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertEmoticonIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={satisfaction}
            variant="determinate"
            classes={
              satisfaction < threshold
                ? {
                    colorPrimary: classes.colorPrimaryRed,
                    barColorPrimary: classes.barColorPrimaryRed
                  }
                : {
                    colorPrimary: classes.colorPrimaryGreen,
                    barColorPrimary: classes.barColorPrimaryGreen
                  }
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
};

CustomerSatisfaction.propTypes = {
  className: PropTypes.string
};

export default CustomerSatisfaction;
