import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EmployeeProfileDetails = ({
  user,
  employee,
  setEmployee,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const canEdit = hasWriteRight(user);

  useEffect(() => {
    if (user.role === 'OWNER') {
      axios
        .get(`${BASE_URL}/company`)
        .then(response => {
          setCompanies(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteEmployee = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/employee/${employee.id}`)
      .then(() => {
        toast.success(getLocaleString('employeeDeleted', user.language));
        navigate('/app/employees', { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        name: employee.name,
        companyId: employee.company.id
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255)
          .required(getLocaleString('nameRequired', user.language))
      })}
      onSubmit={(values, actions) => {
        if (employee.id === 0) {
          // Add new employee
          axios
            .post(`${BASE_URL}/employee`, values)
            .then(response => {
              navigate(`/app/employees/${response.data.id}`, { replace: true });
              toast.success(getLocaleString('employeeCreated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        } else {
          // Update employee
          axios
            .put(`${BASE_URL}/employee/${employee.id}`, values)
            .then(response => {
              setEmployee(response.data);
              toast.success(getLocaleString('employeeUpdated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={
                employee.id === 0 ? (
                  getLocaleString('newSub', user.language)
                ) : (
                  <>
                    {canEdit ? getLocaleString('editSub', user.language) : ''}
                  </>
                )
              }
              title={
                employee.id === 0
                  ? getLocaleString('newEmployee', user.language)
                  : getLocaleString('employeeInfo', user.language)
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={user.role === 'OWNER' ? 6 : 12} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={getLocaleString('name', user.language)}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    InputProps={!canEdit ? { readOnly: true } : {}}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>

                {user.role === 'OWNER' ? (
                  <Grid item md={6} xs={12}>
                    {companies ? (
                      <TextField
                        fullWidth
                        label={getLocaleString('chooseCompany', user.language)}
                        name="companyId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.companyId}
                        variant="outlined"
                      >
                        {companies.map(company => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
            {canEdit ? (
              <>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    {employee.id !== 0 ? (
                      <Box display="flex" justifyContent="flex-start" p={2}>
                        <Button
                          style={{ backgroundColor: '#d11a2a' }}
                          color="primary"
                          variant="contained"
                          onClick={handleDialogOpen}
                        >
                          {getLocaleString('deleteEmployee', user.language)}
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Dialog
                      open={dialogOpen}
                      onClose={handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {getLocaleString('deleteEmployee', user.language)}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {getLocaleString('deleteEmployeeText', user.language)}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                          {getLocaleString('cancel', user.language)}
                        </Button>
                        <Button
                          onClick={handleDeleteEmployee}
                          color="primary"
                          style={{ color: '#d11a2a' }}
                          autoFocus
                        >
                          {getLocaleString('delete', user.language)}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          (user.role === 'OWNER' && !companies)
                        }
                      >
                        {getLocaleString('save', user.language)}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

EmployeeProfileDetails.propTypes = {
  className: PropTypes.string
};

export default EmployeeProfileDetails;
