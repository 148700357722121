import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const CompanyProfile = ({ className, user, company, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={user.avatar}>
            {getInitials(company.name)}
          </Avatar>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {company.name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {company.email}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {company.location}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

CompanyProfile.propTypes = {
  className: PropTypes.string
};

export default CompanyProfile;
