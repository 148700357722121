import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  DollarSign as DollarSignIcon,
  User as UserIcon,
  Users as UsersIcon,
  LogOut as LogOutIcon,
  Home as HomeIcon,
  Calendar as CalendarIcon,
  Phone as PhoneIcon,
  Package as PackageIcon,
  Tool as ToolIcon,
  Layers as LayerIcon,
  Truck as TruckIcon,
  Percent as PercentIcon,
  CheckSquare as CheckSquareIcon,
  Book as BookIcon
} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { getRole } from 'src/utils/roleUtil';
import getLocaleString from 'src/utils/getLocaleString';
import NavItem from './NavItem';
import NavItemContainer from './NavItemContainer';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, handleLogout, user }) => {
  const items = [
    {
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: getLocaleString('dashboard', user.language)
    },
    {
      href: '/app/users',
      icon: UsersIcon,
      title: getLocaleString('users', user.language)
    },
    {
      href: '/app/customers',
      icon: DollarSignIcon,
      title: getLocaleString('customers', user.language)
    },
    {
      href: '/app/visited',
      icon: BookIcon,
      title: getLocaleString('visitedCustomers', user.language)
    },
    {
      href: '/app/equipment',
      icon: ToolIcon,
      title: getLocaleString('equipment', user.language)
    },
    {
      href: '/app/schedule',
      icon: CalendarIcon,
      title: getLocaleString('schedule', user.language)
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: getLocaleString('account', user.language)
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: getLocaleString('settings', user.language)
    }
  ];

  if (user.role === 'ADMIN' || user.role === 'OWNER') {
    items.splice(
      4,
      0,
      {
        href: '/app/buildingtypes',
        icon: HomeIcon,
        title: getLocaleString('buildingTypes', user.language)
      },
      {
        href: '/app/customergroups',
        icon: PackageIcon,
        title: getLocaleString('sections', user.language)
      }
    );
  }

  if (user.role === 'OWNER') {
    items.splice(1, 0, {
      href: '/app/companies',
      icon: PhoneIcon,
      title: getLocaleString('companies', user.language)
    });
  }

  const containerItems = [
    {
      icon: LayerIcon,
      title: getLocaleString('checkIn', user.language),
      items: [
        {
          href: '/app/employees',
          icon: TruckIcon,
          title: getLocaleString('employees', user.language)
        },
        {
          href: '/app/statistics',
          icon: PercentIcon,
          title: getLocaleString('statistics', user.language)
        },
        {
          href: '/app/checkin',
          icon: CheckSquareIcon,
          title: getLocaleString('checkIn', user.language)
        }
      ]
    }
  ];

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const pcContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/app/account"
        >
          {getInitials(user.name)}
        </Avatar>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {getRole(user, user.language)}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.company.name}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          {containerItems.map(item => (
            <NavItemContainer
              icon={item.icon}
              title={item.title}
              key={item.title}
              items={item.items}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  const mobileContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/app/account"
        >
          {getInitials(user.name)}
        </Avatar>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {getRole(user, user.language)}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.company.name}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          <>
            {items.map(item => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
            {containerItems.map(item => (
              <NavItemContainer
                icon={item.icon}
                title={item.title}
                key={item.title}
                items={item.items}
              />
            ))}
            <NavItem
              key="Log Out"
              onClick={handleLogout}
              href="/login"
              title={getLocaleString('logout', user.language)}
              icon={LogOutIcon}
            />
          </>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {mobileContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {pcContent}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
