import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(() => ({
  root: {}
}));

const CompanyProfileDetails = ({
  user,
  company,
  setCompany,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteCompany = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/company/${company.id}`)
      .then(() => {
        toast.success(getLocaleString('companyDeleted', user.language));
        navigate('/app/companies', { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        name: company.name,
        email: company.email,
        phone: company.phone,
        location: company.location,
        threshold: company.threshold,
        userLimit: company.userLimit,
        disabled: company.disabled,
        simpleInspections: company.simpleInspections
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(getLocaleString('emailValid', user.language))
          .max(255)
          .required(getLocaleString('emailRequired', user.language)),
        name: Yup.string()
          .max(255)
          .required(getLocaleString('nameRequired', user.language)),
        phone: Yup.string().max(15, getLocaleString('phoneMax', user.language)),
        location: Yup.string().required(
          getLocaleString('locationRequired', user.language)
        )
      })}
      onSubmit={(values, actions) => {
        let userLimit = Number(values.userLimit);
        if (Number.isNaN(userLimit)) {
          userLimit = 0;
        }

        values.userLimit = userLimit;

        let threshold = Number(values.threshold);
        if (Number.isNaN(threshold)) {
          threshold = 0;
        }

        values.threshold = threshold;

        if (company.id === 0) {
          // Add new company
          axios
            .post(`${BASE_URL}/company`, values)
            .then(response => {
              navigate(`/app/companies/${response.data.id}`, { replace: true });
              toast.success(getLocaleString('companyCreated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        } else {
          // Update company
          axios
            .put(`${BASE_URL}/company/${company.id}`, values)
            .then(response => {
              setCompany(response.data);
              toast.success(getLocaleString('companyUpdated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values,
        setFieldValue
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={
                company.id === 0
                  ? getLocaleString('newSub', user.language)
                  : getLocaleString('editSub', user.language)
              }
              title={
                company.id === 0
                  ? getLocaleString('newCompany', user.language)
                  : getLocaleString('companyInfo', user.language)
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={getLocaleString('name', user.language)}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label={getLocaleString('emailAddress', user.language)}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    label={getLocaleString('phoneNumber', user.language)}
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                    label={getLocaleString('location', user.language)}
                    name="location"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.location}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(touched.userLimit && errors.userLimit)}
                    helperText={touched.userLimit && errors.userLimit}
                    label={getLocaleString('userLimit', user.language)}
                    name="userLimit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.userLimit}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                    error={Boolean(touched.threshold && errors.threshold)}
                    helperText={touched.threshold && errors.threshold}
                    label={getLocaleString('threshold', user.language)}
                    name="threshold"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.threshold}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    checked={values.disabled}
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('disabled', e.target.checked);
                    }}
                    control={<Switch />}
                    label={getLocaleString('disabled', user.language)}
                    name="disabled"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    checked={values.simpleInspections}
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('simpleInspections', e.target.checked);
                    }}
                    control={<Switch />}
                    label={getLocaleString(
                      'simplifiedInspections',
                      user.language
                    )}
                    name="simpleInspections"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Grid container spacing={3}>
              <Grid item md={6} xs={6}>
                {company.id !== 0 ? (
                  <Box display="flex" justifyContent="flex-start" p={2}>
                    <Button
                      style={{ backgroundColor: '#d11a2a' }}
                      color="primary"
                      variant="contained"
                      onClick={handleDialogOpen}
                    >
                      {getLocaleString('deleteCompany', user.language)}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <Dialog
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {getLocaleString('deleteCompany', user.language)}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {getLocaleString('deleteCompanyText', user.language)}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      {getLocaleString('cancel', user.language)}
                    </Button>
                    <Button
                      onClick={handleDeleteCompany}
                      color="primary"
                      style={{ color: '#d11a2a' }}
                      autoFocus
                    >
                      {getLocaleString('delete', user.language)}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item md={6} xs={6}>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {getLocaleString('save', user.language)}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

CompanyProfileDetails.propTypes = {
  className: PropTypes.string
};

export default CompanyProfileDetails;
