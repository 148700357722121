import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography,
  Divider
} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  AttachMoney as PermIdentityIcon
} from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import { Carousel } from 'react-responsive-carousel';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import Inspections from 'src/components/Inspections';
import InspectionYearGraph from 'src/components/InspectionYearGraph';
import getLocaleString from 'src/utils/getLocaleString';
import CustomerProfile from './CustomerProfile';
import CustomerProfileDetails from './CustomerProfileDetails';
import BuildingsListView from './BuildingsListView';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  carouselItem: {
    paddingLeft: 40,
    paddingRight: 40
  },
  carouselInspectionItem: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 24
  }
}));

const CustomerView = ({ user }) => {
  const classes = useStyles();
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [inspections, setInspections] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (customerId === '0') {
      setCustomer({
        id: 0,
        name: '',
        contacts: [],
        phone: '',
        language: 'en',
        company: user.company,
        buildings: [],
        customerGroup: null,
        frequency: 3,
        frequencyType: 2,
        description: ''
      });
    } else {
      axios
        .get(`${BASE_URL}/customer/${customerId}`)
        .then(response => {
          setCustomer(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });

      axios
        .get(`${BASE_URL}/customer/${customerId}/inspection`)
        .then(response => {
          setInspections(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });

      axios
        .get(`${BASE_URL}/customer/${customerId}/statistics`)
        .then(response => {
          setGraphData(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, [customerId]);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('customer', user.language)}
    >
      {!customer ? (
        <></>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Typography color="textPrimary" className={classes.link}>
                  <PermIdentityIcon className={classes.icon} />
                  {customer.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
            {customerId !== '0' ? (
              <Grid item lg={4} md={6} xs={12}>
                <CustomerProfile user={user} customer={customer} />
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              item
              lg={customerId !== '0' ? 8 : 12}
              md={customerId !== '0' ? 6 : 12}
              xs={12}
            >
              <CustomerProfileDetails
                user={user}
                customer={customer}
                setCustomer={setCustomer}
              />
            </Grid>
            {customerId !== '0' ? (
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Divider />
                <Carousel
                  showArrows
                  showStatus={false}
                  swipeable={false}
                  showThumbs={false}
                  infiniteLoop
                  showIndicators={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="control-arrow control-prev"
                        style={{
                          margin: 'auto',
                          height: 300,
                          opacity: 1,
                          borderRadius: 6,
                          backgroundColor: 'rgba(52, 52, 52, 0.5)'
                        }}
                      />
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="control-arrow control-next"
                        style={{
                          margin: 'auto',
                          height: 300,
                          opacity: 1,
                          borderRadius: 6,
                          backgroundColor: 'rgba(52, 52, 52, 0.5)'
                        }}
                      />
                    )
                  }
                >
                  <div className={classes.carouselItem}>
                    <BuildingsListView
                      user={user}
                      customer={customer}
                      setCustomer={setCustomer}
                    />
                  </div>

                  {inspections.length > 0 ? (
                    <div className={classes.carouselInspectionItem}>
                      <Inspections
                        inspections={inspections}
                        setInspections={setInspections}
                        user={user}
                      />
                    </div>
                  ) : (
                    <Typography variant="h4">
                      {getLocaleString('noInspections', user.language)}
                    </Typography>
                  )}
                  <div className={classes.carouselInspectionItem}>
                    <InspectionYearGraph
                      title={getLocaleString(
                        'averageInspectionScore',
                        user.language
                      )}
                      stats={graphData}
                      user={user}
                    />
                  </div>
                </Carousel>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default CustomerView;
