import { configureStore } from '@reduxjs/toolkit';
import buildingSlice from './buildingSlice';
import customerGroupSlice from './customerGroupSlice';
import customerSlice from './customerSlice';
import eventSlice from './eventSlice';
import materialSlice from './materialSlice';
import statisticSlice from './statisticSlice';
import userSlice from './userSlice';

export default configureStore({
  reducer: {
    user: userSlice,
    customer: customerSlice,
    building: buildingSlice,
    customerGroup: customerGroupSlice,
    material: materialSlice,
    event: eventSlice,
    statistic: statisticSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: false
});
