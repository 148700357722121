import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchCustomerGroups = createAsyncThunk(
  '/customerGroup/fetchCustomerGroups',
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/customer-group`);
      return res.data;
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const customerGroupSlice = createSlice({
  name: 'customerGroup',
  initialState: {
    customerGroups: null
  },
  reducers: {
    addCustomerGroup(state, action) {
      if (state.customerGroups) {
        state.customerGroups.push(action.payload);
      }
    },
    updateCustomerGroup(state, action) {
      if (state.customerGroups) {
        state.customerGroups[
          state.customerGroups.findIndex(cg => cg.id === action.payload.id)
        ] = action.payload;
      }
    },
    deleteCustomerGroup(state, action) {
      if (state.customerGroups) {
        state.customerGroups = state.customerGroups.filter(
          cg => cg.id !== action.payload
        );
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchCustomerGroups.fulfilled, (state, action) => {
      state.customerGroups = action.payload;
    });
  }
});

export const {
  addCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup
} = customerGroupSlice.actions;

export default customerGroupSlice.reducer;
