import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import { FilePlus as PlusIcon } from 'react-feather';
import lodash from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchBuildingTypes,
  addBuildingType,
  updateBuildingType,
  deleteBuildingType
} from 'src/redux/buildingSlice';
import { BASE_URL } from 'src/constants/api';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';
import ElementList from 'src/components/ElementList';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = ({ user }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { buildingTypeId } = useParams();
  const [buildingType, setBuildingType] = useState(null);
  const [selectedFloorIndex, setSelectedFloorIndex] = useState(null);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [renameType, setRenameType] = useState(null);
  const [rename, setRename] = useState('');
  const buildingTypes = useSelector(state => state.building.buildingTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (buildingTypeId === '0') {
      setBuildingType({
        id: 0,
        name: '',
        floors: []
      });
    } else {
      if (buildingTypes === null) {
        dispatch(fetchBuildingTypes());
      } else {
        setBuildingType(buildingTypes.find(bt => bt.id == buildingTypeId));
      }
    }
  }, [buildingTypeId, buildingTypes]);

  useEffect(() => {
    if (buildingType && buildingType.floors.length > 0) {
      if (selectedFloorIndex === null) {
        setSelectedFloorIndex(0);
      } else if (buildingType.floors[selectedFloorIndex].rooms.length > 0) {
        if (selectedRoomIndex === null) {
          setSelectedRoomIndex(0);
        } else if (
          buildingType.floors[selectedFloorIndex].rooms[selectedRoomIndex]
            .elements.length > 0 &&
          selectedElementIndex === null
        ) {
          setSelectedElementIndex(0);
        }
      }
    }
  }, [
    buildingType,
    selectedFloorIndex,
    selectedRoomIndex,
    selectedElementIndex
  ]);

  const handleFloorClick = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(null);
    setSelectedFloorIndex(index);
  };

  const handleRoomClick = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(index);
  };

  const handleElementClick = index => {
    setSelectedElementIndex(index);
  };

  const handleAddFloor = name => {
    const buildingTypeCopy = lodash.cloneDeep(buildingType);
    buildingTypeCopy.floors.push({ id: 0, name, rooms: [] });
    setBuildingType(buildingTypeCopy);
  };

  const handleAddRoom = name => {
    const buildingTypeCopy = lodash.cloneDeep(buildingType);
    buildingTypeCopy.floors[selectedFloorIndex].rooms.push({
      id: 0,
      name,
      elements: []
    });
    setBuildingType(buildingTypeCopy);
  };

  const handleAddElement = name => {
    const buildingTypeCopy = lodash.cloneDeep(buildingType);
    buildingTypeCopy.floors[selectedFloorIndex].rooms[
      selectedRoomIndex
    ].elements.push({
      id: 0,
      name
    });
    setBuildingType(buildingTypeCopy);
  };

  const handleRemoveFloor = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(null);
    setSelectedFloorIndex(null);

    const buildingTypeCopy = lodash.cloneDeep(buildingType);
    buildingTypeCopy.floors.splice(index, 1);
    setBuildingType(buildingTypeCopy);
  };

  const handleRemoveRoom = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(null);

    const buildingTypeCopy = lodash.cloneDeep(buildingType);
    buildingTypeCopy.floors[selectedFloorIndex].rooms.splice(index, 1);
    setBuildingType(buildingTypeCopy);
  };

  const handleRemoveElement = index => {
    setSelectedElementIndex(null);

    const buildingTypeCopy = lodash.cloneDeep(buildingType);
    buildingTypeCopy.floors[selectedFloorIndex].rooms[
      selectedRoomIndex
    ].elements.splice(index, 1);
    setBuildingType(buildingTypeCopy);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteBuildingType = () => {
    handleDeleteDialogClose();
    axios
      .delete(`${BASE_URL}/buildingtype/${buildingType.id}`)
      .then(() => {
        dispatch(deleteBuildingType(buildingType.id));
        toast.success(getLocaleString('buildingTypeDeleted', user.language));
        navigate('/app/buildingtypes', { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleRenameDialogOpen = name => {
    setRename(name);
    setRenameDialogOpen(true);
  };

  const handleRenameDialogOpenFloor = name => {
    setRenameType('floor');
    handleRenameDialogOpen(name);
  };

  const handleRenameDialogOpenRoom = name => {
    setRenameType('room');
    handleRenameDialogOpen(name);
  };

  const handleRenameDialogOpenElement = name => {
    setRenameType('element');
    handleRenameDialogOpen(name);
  };

  const handleRenameDialogClose = () => {
    setRenameType(null);
    setRename('');
    setRenameDialogOpen(false);
  };

  const handleRenameBuildingType = () => {
    handleRenameDialogClose();
    const buildingTypeCopy = lodash.cloneDeep(buildingType);

    switch (renameType) {
      case 'floor':
        buildingTypeCopy.floors[selectedFloorIndex].name = rename;
        break;
      case 'room':
        buildingTypeCopy.floors[selectedFloorIndex].rooms[
          selectedRoomIndex
        ].name = rename;
        break;
      case 'element':
        buildingTypeCopy.floors[selectedFloorIndex].rooms[
          selectedRoomIndex
        ].elements[selectedElementIndex].name = rename;
        break;
      default:
        break;
    }

    setBuildingType(buildingTypeCopy);
  };

  return (
    <Page
      className={classes.root}
      title={getLocaleString('buildingType', user.language)}
    >
      <Container maxWidth="lg">
        {!buildingType ? (
          <></>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  name: buildingType.name
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(255)
                    .required(getLocaleString('nameRequired', user.language))
                })}
                onSubmit={(values, actions) => {
                  const buildingTypeCopy = lodash.cloneDeep(buildingType);
                  buildingTypeCopy.name = values.name;

                  if (buildingType.id === 0) {
                    axios
                      .post(`${BASE_URL}/buildingtype`, buildingTypeCopy)
                      .then(response => {
                        dispatch(addBuildingType(response.data));
                        navigate(`/app/buildingtypes/${response.data.id}`, {
                          replace: true
                        });
                        toast.success(
                          getLocaleString('buildingTypeCreated', user.language)
                        );
                        actions.setSubmitting(false);
                      })
                      .catch(err => {
                        if (err.response) {
                          toast.error(err.response.data.message);
                        } else {
                          toast.error(err.message);
                        }
                        actions.setSubmitting(false);
                      });
                  } else {
                    axios
                      .put(
                        `${BASE_URL}/buildingtype/${buildingType.id}`,
                        buildingTypeCopy
                      )
                      .then(response => {
                        dispatch(updateBuildingType(response.data));
                        setBuildingType(response.data);
                        toast.success(
                          getLocaleString('buildingTypeUpdated', user.language)
                        );
                        actions.setSubmitting(false);
                      })
                      .catch(err => {
                        if (err.response) {
                          toast.error(err.response.data.message);
                        } else {
                          toast.error(err.message);
                        }
                        actions.setSubmitting(false);
                      });
                  }
                }}
              >
                {({
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  values
                }) => (
                  <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item lg={9} md={9} xs={12}>
                            <Box>
                              <TextField
                                fullWidth
                                name="name"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SvgIcon fontSize="small" color="action">
                                        <PlusIcon />
                                      </SvgIcon>
                                    </InputAdornment>
                                  )
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                placeholder={getLocaleString(
                                  'name',
                                  user.language
                                )}
                                variant="outlined"
                              />
                            </Box>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              style={{ height: '100%' }}
                            >
                              <Button
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting || !isValid}
                                style={{ width: '100%' }}
                                type="submit"
                              >
                                {buildingType.id === 0
                                  ? getLocaleString(
                                      'addBuildingType',
                                      user.language
                                    )
                                  : getLocaleString(
                                      'updateBuildingType',
                                      user.language
                                    )}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </form>
                )}
              </Formik>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ElementList
                handleItemClick={handleFloorClick}
                title={getLocaleString('groups', user.language)}
                buttonTitle={getLocaleString('addFloor', user.language)}
                items={buildingType.floors}
                selectedIndex={selectedFloorIndex}
                handleAddItem={handleAddFloor}
                handleRemoveItem={handleRemoveFloor}
                handleRename={handleRenameDialogOpenFloor}
              />
            </Grid>
            {selectedFloorIndex !== null &&
            buildingType.floors[selectedFloorIndex] ? (
              <>
                <Grid item md={4} sm={6} xs={12}>
                  <ElementList
                    handleItemClick={handleRoomClick}
                    title={getLocaleString('rooms', user.language)}
                    buttonTitle={getLocaleString('addRoom', user.language)}
                    items={buildingType.floors[selectedFloorIndex].rooms}
                    selectedIndex={selectedRoomIndex}
                    handleAddItem={handleAddRoom}
                    handleRemoveItem={handleRemoveRoom}
                    handleRename={handleRenameDialogOpenRoom}
                  />
                </Grid>
                {selectedRoomIndex !== null &&
                buildingType.floors[selectedFloorIndex].rooms[
                  selectedRoomIndex
                ] ? (
                  <Grid item md={4} sm={6} xs={12}>
                    <ElementList
                      handleItemClick={handleElementClick}
                      title={getLocaleString('elements', user.language)}
                      buttonTitle={getLocaleString('addElement', user.language)}
                      items={
                        buildingType.floors[selectedFloorIndex].rooms[
                          selectedRoomIndex
                        ].elements
                      }
                      selectedIndex={selectedElementIndex}
                      handleAddItem={handleAddElement}
                      handleRemoveItem={handleRemoveElement}
                      handleRename={handleRenameDialogOpenElement}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <Dialog
              open={renameDialogOpen}
              onClose={handleRenameDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="rename"
                  type="text"
                  fullWidth
                  value={rename}
                  onChange={e => setRename(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRenameDialogClose} color="primary">
                  {getLocaleString('cancel', user.language)}
                </Button>
                <Button
                  onClick={handleRenameBuildingType}
                  disabled={rename.trim() === ''}
                  color="primary"
                >
                  {getLocaleString('update', user.language)}
                </Button>
              </DialogActions>
            </Dialog>
            {buildingType.id !== 0 ? (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ width: '100%', backgroundColor: '#d11a2a' }}
                  type="button"
                  onClick={handleDeleteDialogOpen}
                >
                  {getLocaleString('deleteBuildingType', user.language)}
                </Button>
                <Dialog
                  open={deleteDialogOpen}
                  onClose={handleDeleteDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {getLocaleString('deleteBuildingType', user.language)}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {getLocaleString('deleteBuildingTypeText', user.language)}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                      {getLocaleString('cancel', user.language)}
                    </Button>
                    <Button
                      onClick={handleDeleteBuildingType}
                      color="primary"
                      style={{ color: '#d11a2a' }}
                      autoFocus
                    >
                      {getLocaleString('delete', user.language)}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default SettingsView;
