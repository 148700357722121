import React, { useState } from 'react';

const AddRowsCustomComponent = ({ addRows, addText, lignesText }) => {
  const [value, setValue] = useState(1);
  const [rawValue, setRawValue] = useState(String(value));

  return (
    <div className="dsg-add-row">
      <button
        type="button"
        className="dsg-add-row-btn"
        onClick={() => addRows(value)}
      >
        {addText}
      </button>{' '}
      <input
        className="dsg-add-row-input"
        value={rawValue}
        onBlur={() => setRawValue(String(value))}
        type="number"
        min={1}
        onChange={e => {
          setRawValue(e.target.value);
          setValue(Math.max(1, Math.round(Number(e.target.value) || 0)));
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            addRows(value);
          }
        }}
      />
      {` ${lignesText}`}
    </div>
  );
};

export default AddRowsCustomComponent;
