import en from '../locale/en.json';
import nl from '../locale/nl.json';
import fr from '../locale/fr.json';

export default (key, locale) => {
  switch (locale) {
    case 'nl':
      return nl[key];
    case 'fr':
      return fr[key];
    default:
      return en[key];
  }
};
