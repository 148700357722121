import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(() => ({
  root: {}
}));

const InspectionYearGraph = ({ stats, title, className, user, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [...stats]
      .sort((a, b) => (a.year > b.year ? -1 : b.year > a.year ? 1 : 0))
      .map(stat => {
        return {
          backgroundColor:
            stat.year === new Date().getFullYear()
              ? colors.indigo[500]
              : colors.grey[400],
          data: stat.data,
          label: stat.year
        };
      }),
    labels: [
      getLocaleString('jan', user.language),
      getLocaleString('feb', user.language),
      getLocaleString('mar', user.language),
      getLocaleString('apr', user.language),
      getLocaleString('may', user.language),
      getLocaleString('jun', user.language),
      getLocaleString('jul', user.language),
      getLocaleString('aug', user.language),
      getLocaleString('sep', user.language),
      getLocaleString('oct', user.language),
      getLocaleString('nov', user.language),
      getLocaleString('dec', user.language)
    ]
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            max: 100,
            callback: val => {
              return `${val}%`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        label: tooltipItem => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }

          label += `${Math.round(tooltipItem.yLabel * 100) / 100}%`;

          return label;
        }
      }
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

InspectionYearGraph.propTypes = {
  className: PropTypes.string
};

export default InspectionYearGraph;
