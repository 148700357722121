import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchCustomers = createAsyncThunk(
  '/customer/fetchCustomers',
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/customer`);
      return res.data;
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const fetchCustomersVisited = createAsyncThunk(
  '/customer/fetchCustomersVisited',
  async year => {
    try {
      const res = await axios.get(
        `${BASE_URL}/building/visitedCustomers/${year}`
      );
      return { year, data: res.data };
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customers: null,
    visitedCustomers: {}
  },
  reducers: {
    addCustomer(state, action) {
      if (state.customers) {
        state.customers.push(action.payload);
      }
    },
    updateCustomer(state, action) {
      if (state.customers) {
        state.customers[
          state.customers.findIndex(c => c.id === action.payload.id)
        ] = action.payload;
      }
    },
    deleteCustomer(state, action) {
      if (state.customers) {
        state.customers = state.customers.filter(c => c.id != action.payload);
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.customers = action.payload;
    }),
      builder.addCase(fetchCustomersVisited.fulfilled, (state, action) => {
        state.visitedCustomers[action.payload.year] = action.payload.data;
      });
  }
});

export const {
  addCustomer,
  updateCustomer,
  deleteCustomer
} = customerSlice.actions;

export default customerSlice.reducer;
