import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { MainLayout, LandingLayout } from 'src/layouts/MainLayout';
import ContactPage from 'src/views/contact';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import CustomerView from 'src/views/customer/CustomerView';
import CompanyListView from 'src/views/company/CompanyListView';
import CompanyView from 'src/views/company/CompanyView';
import UserListView from 'src/views/user/UserListView';
import BuildingListView from 'src/views/building/BuildingListView';
import BuildingView from 'src/views/building/BuildingView';
import BuildingTypeListView from 'src/views/buildingType/BuildingTypeListView';
import BuildingTypeView from 'src/views/buildingType/BuildingTypeView';
import CustomerGroupListView from 'src/views/customerGroup/CustomerGroupListView';
import CustomerGroupView from 'src/views/customerGroup/CustomerGroupView';
import FloorView from 'src/views/floor/FloorView';
import RoomView from 'src/views/room/RoomView';
import ElementView from 'src/views/element/ElementView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import SettingsView from 'src/views/setting/SettingsView';
import ScheduleView from 'src/views/schedule/ScheduleView';
import MaterialListView from 'src/views/material/MaterialListView';
import CheckInView from 'src/views/checkin/checkin/CheckInView';
import StatisticsView from 'src/views/checkin/statistics/StatisticsView';
import EmployeeListView from 'src/views/checkin/employee/EmployeeListView';
import EmployeeView from 'src/views/checkin/employee/EmployeeView';
import LandingPage from 'src/views/landingPage';
import VisitedCustomerPage from 'src/views/visitedCustomers/VisitedCustomerListView';

const routes = (user, setUser) => {
  if (!user) {
    return [
      {
        path: '/login',
        element: <MainLayout />,
        children: [{ path: '', element: <LoginView setUser={setUser} /> }]
      },
      {
        path: '/contact',
        element: <MainLayout />,
        children: [{ path: '', element: <ContactPage /> }]
      },
      {
        path: '/',
        element: <LandingLayout />,
        children: [
          { path: '', element: <LandingPage /> },
          { path: '*', element: <Navigate to="/" /> }
        ]
      }
    ];
  }

  switch (user.role) {
    case 'OWNER':
      return [
        {
          path: 'app',
          element: <DashboardLayout user={user} setUser={setUser} />,
          children: [
            {
              path: 'account',
              element: <AccountView user={user} isProfile setUser={setUser} />
            },
            { path: 'customers', element: <CustomerListView user={user} /> },
            {
              path: 'customers/:customerId',
              element: <CustomerView user={user} />
            },
            { path: 'companies', element: <CompanyListView user={user} /> },
            {
              path: 'companies/:companyId',
              element: <CompanyView user={user} />
            },
            { path: 'users', element: <UserListView user={user} /> },
            {
              path: 'users/:userId',
              element: (
                <AccountView user={user} isProfile={false} setUser={setUser} />
              )
            },
            { path: 'buildings', element: <BuildingListView user={user} /> },
            {
              path: 'buildings/:buildingId',
              element: <BuildingView user={user} />
            },
            {
              path: 'buildingtypes',
              element: <BuildingTypeListView user={user} />
            },
            {
              path: 'buildingtypes/:buildingTypeId',
              element: <BuildingTypeView user={user} />
            },
            {
              path: 'customergroups',
              element: <CustomerGroupListView user={user} />
            },
            {
              path: 'customergroups/:customerGroupId',
              element: <CustomerGroupView user={user} />
            },
            {
              path: 'floors/:floorId',
              element: <FloorView user={user} />
            },
            {
              path: 'rooms/:roomId',
              element: <RoomView user={user} />
            },
            {
              path: 'elements/:elementId',
              element: <ElementView user={user} />
            },
            { path: 'equipment', element: <MaterialListView user={user} /> },
            { path: 'dashboard', element: <DashboardView user={user} /> },
            { path: 'schedule', element: <ScheduleView user={user} /> },
            { path: 'employees', element: <EmployeeListView user={user} /> },
            {
              path: 'employees/:employeeId',
              element: <EmployeeView user={user} />
            },
            { path: 'statistics', element: <StatisticsView user={user} /> },
            { path: 'checkin', element: <CheckInView user={user} /> },
            {
              path: 'settings',
              element: <SettingsView user={user} setUser={setUser} />
            },
            { path: 'visited', element: <VisitedCustomerPage user={user} /> },
            { path: '*', element: <Navigate to="/app/dashboard" /> }
          ]
        },
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: 'contact', element: <ContactPage /> },
            { path: 'login', element: <Navigate to="/app/dashboard" /> },
            { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/app/dashboard" /> },
            { path: '*', element: <Navigate to="/app/dashboard" /> }
          ]
        }
      ];

    case 'ADMIN':
      return [
        {
          path: 'app',
          element: <DashboardLayout user={user} setUser={setUser} />,
          children: [
            {
              path: 'account',
              element: <AccountView user={user} isProfile setUser={setUser} />
            },
            { path: 'customers', element: <CustomerListView user={user} /> },
            {
              path: 'customers/:customerId',
              element: <CustomerView user={user} />
            },
            { path: 'users', element: <UserListView user={user} /> },
            {
              path: 'users/:userId',
              element: (
                <AccountView user={user} isProfile={false} setUser={setUser} />
              )
            },
            { path: 'buildings', element: <BuildingListView user={user} /> },
            {
              path: 'buildings/:buildingId',
              element: <BuildingView user={user} />
            },
            {
              path: 'buildingtypes',
              element: <BuildingTypeListView user={user} />
            },
            {
              path: 'buildingtypes/:buildingTypeId',
              element: <BuildingTypeView user={user} />
            },
            {
              path: 'customergroups',
              element: <CustomerGroupListView user={user} />
            },
            {
              path: 'customergroups/:customerGroupId',
              element: <CustomerGroupView user={user} />
            },
            {
              path: 'floors/:floorId',
              element: <FloorView user={user} />
            },
            {
              path: 'rooms/:roomId',
              element: <RoomView user={user} />
            },
            {
              path: 'elements/:elementId',
              element: <ElementView user={user} />
            },
            { path: 'equipment', element: <MaterialListView user={user} /> },
            { path: 'dashboard', element: <DashboardView user={user} /> },
            { path: 'schedule', element: <ScheduleView user={user} /> },
            { path: 'employees', element: <EmployeeListView user={user} /> },
            {
              path: 'employees/:employeeId',
              element: <EmployeeView user={user} />
            },
            { path: 'statistics', element: <StatisticsView user={user} /> },
            { path: 'checkin', element: <CheckInView user={user} /> },
            {
              path: 'settings',
              element: <SettingsView user={user} setUser={setUser} />
            },
            { path: 'visited', element: <VisitedCustomerPage user={user} /> },
            { path: '*', element: <Navigate to="/app/dashboard" /> }
          ]
        },
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: 'contact', element: <ContactPage /> },
            { path: 'login', element: <Navigate to="/app/dashboard" /> },
            { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/app/dashboard" /> },
            { path: '*', element: <Navigate to="/app/dashboard" /> }
          ]
        }
      ];

    case 'INSPECTOR':
      return [
        {
          path: 'app',
          element: <DashboardLayout user={user} setUser={setUser} />,
          children: [
            {
              path: 'account',
              element: <AccountView user={user} isProfile setUser={setUser} />
            },
            { path: 'customers', element: <CustomerListView user={user} /> },
            {
              path: 'customers/:customerId',
              element: <CustomerView user={user} />
            },
            { path: 'users', element: <UserListView user={user} /> },
            { path: 'buildings', element: <BuildingListView user={user} /> },
            {
              path: 'buildings/:buildingId',
              element: <BuildingView user={user} />
            },
            {
              path: 'floors/:floorId',
              element: <FloorView user={user} />
            },
            {
              path: 'rooms/:roomId',
              element: <RoomView user={user} />
            },
            {
              path: 'elements/:elementId',
              element: <ElementView user={user} />
            },
            { path: 'equipment', element: <MaterialListView user={user} /> },
            { path: 'dashboard', element: <DashboardView user={user} /> },
            { path: 'schedule', element: <ScheduleView user={user} /> },
            { path: 'employees', element: <EmployeeListView user={user} /> },
            {
              path: 'employees/:employeeId',
              element: <EmployeeView user={user} />
            },
            { path: 'statistics', element: <StatisticsView user={user} /> },
            { path: 'checkin', element: <CheckInView user={user} /> },
            {
              path: 'settings',
              element: <SettingsView user={user} setUser={setUser} />
            },
            { path: 'visited', element: <VisitedCustomerPage user={user} /> },
            { path: '*', element: <Navigate to="/app/dashboard" /> }
          ]
        },
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: 'contact', element: <ContactPage /> },
            { path: 'login', element: <Navigate to="/app/dashboard" /> },
            { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/app/dashboard" /> },
            { path: '*', element: <Navigate to="/app/dashboard" /> }
          ]
        }
      ];

    default:
      return [
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: 'contact', element: <ContactPage /> },
            { path: 'login', element: <LoginView setUser={setUser} /> },
            { path: '', element: <Navigate to="/login" /> },
            { path: '*', element: <Navigate to="/login" /> }
          ]
        }
      ];
  }
};

export default routes;
