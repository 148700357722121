import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  LinearProgress
} from '@material-ui/core';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import thresholds from 'src/constants/thresholds';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const Threshold = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const [threshold, setThreshold] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/company/threshold`)
      .then(response => {
        setThreshold(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  if (threshold) {
    return (
      <Formik
        initialValues={{
          threshold
        }}
        onSubmit={(values, actions) => {
          axios
            .put(`${BASE_URL}/company/threshold?threshold=${values.threshold}`)
            .then(response => {
              setThreshold(response.data);
              toast.success(getLocaleString('thresholdUpdated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        }}
        enableReinitialize
      >
        {({ handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader={getLocaleString('updateThreshold', user.language)}
                title={getLocaleString('threshold', user.language)}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={6} wrap="wrap">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={getLocaleString('threshold', user.language)}
                      name="threshold"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.threshold}
                      variant="outlined"
                    >
                      {thresholds.map(t => (
                        <option key={t} value={t}>
                          {t}%
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || values.threshold === threshold}
                  type="submit"
                >
                  {getLocaleString('save', user.language)}
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    );
  }
  return <LinearProgress />;
};

Threshold.propTypes = {
  className: PropTypes.string
};

export default Threshold;
