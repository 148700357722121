import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomers } from 'src/redux/customerSlice';
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import getLocaleString from 'src/utils/getLocaleString';
import { getSheetData, getColumnLetter } from 'src/utils/excelUtils';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = ({ user }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const customers = useSelector(state => state.customer.customers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customers === null) {
      dispatch(fetchCustomers());
    }
  }, []);

  const handleDownload = async () => {
    setIsDownloading(true);
    const rows = [];
    customers.forEach(c => {
      c.buildings.forEach(b => {
        let customerIdstring = c.identifier.toString();
        let buildingIdstring = b.identifier.toString();

        while (customerIdstring.length < 4) {
          customerIdstring = '0' + customerIdstring;
        }

        while (buildingIdstring.length < 6) {
          buildingIdstring = '0' + buildingIdstring;
        }
        const stringId = customerIdstring + buildingIdstring;

        rows.push({
          id: stringId,
          customer: c.name,
          building: b.name ? b.name : b.address,
          email: c.email,
          phone: c.phone,
          section: c.customerGroup && c.customerGroup.name,
          withinFrequency: c.withinFrequency
            ? getLocaleString('yes', user.language)
            : getLocaleString('no', user.language)
        });
      });
    });

    if (rows.length > 0) {
      const header = [
        'ID',
        getLocaleString('customer', user.language),
        getLocaleString('building', user.language),
        getLocaleString('email', user.language),
        getLocaleString('phone', user.language),
        getLocaleString('section', user.language),
        getLocaleString('inspectionDone', user.language)
      ];

      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = dd + '-' + mm + '-' + yyyy;

      XlsxPopulate.fromBlankAsync().then(async workbook => {
        const sheet1 = workbook
          .sheet(0)
          .name(
            `${getLocaleString('customers', user.language)} ${formattedToday}`
          );

        const sheetData = getSheetData(rows, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell('A1').value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = getColumnLetter(totalColumns - 1);
        sheet1.row(1).style('bold', true);
        sheet1.range(`A1:${endColumn}1`).style('fill', 'BFBFBF');
        range.style('border', true);
        sheet1.column('A').width(15);
        sheet1.column('B').width(33);
        sheet1.column('C').width(60);
        sheet1.column('D').width(42);
        sheet1.column('E').width(15);
        sheet1.column('F').width(21);
        sheet1.column('G').width(20);

        return workbook.outputAsync().then(res => {
          saveAs(
            res,
            `${getLocaleString(
              'customers',
              user.language
            )} ${formattedToday}.xlsx`
          );
          setIsDownloading(false);
        });
      });
    }
  };

  return (
    <Page
      className={classes.root}
      title={getLocaleString('customers', user.language)}
    >
      <Container maxWidth={false}>
        {customers === null ? (
          <Loader small />
        ) : (
          <>
            <Toolbar
              user={user}
              setSearch={setSearch}
              handleDownload={handleDownload}
              isDownloading={isDownloading}
            />
            <Box mt={3}>
              <Results user={user} customers={customers} search={search} />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default CustomerListView;
