import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Button
} from '@material-ui/core';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  user,
  materials,
  search,
  setOpenDialog,
  setDeleteDialogOpen,
  setSelectedMaterial,
  ...rest
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = material => {
    setSelectedMaterial(material);
    setOpenDialog(true);
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {getLocaleString('name', user.language)}
                </TableCell>
                {hasWriteRight(user) ? (
                  <TableCell align="center">
                    {getLocaleString('delete', user.language)}
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {materials
                .filter(material =>
                  material.name.toLowerCase().includes(search.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(material => (
                  <TableRow
                    hover
                    key={material.id}
                    onClick={e => {
                      if (
                        !e.target.classList.contains('MuiButton-label') &&
                        !e.target.classList.contains('MuiButtonBase-root')
                      ) {
                        handleRowClick(material);
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell align="center">{material.name}</TableCell>
                    {hasWriteRight(user) ? (
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setSelectedMaterial(material);
                            setDeleteDialogOpen(true);
                          }}
                          color="primary"
                          style={{ color: 'white', backgroundColor: '#d11a2a' }}
                        >
                          {getLocaleString('delete', user.language)}
                        </Button>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
        count={
          materials.filter(material =>
            material.name.toLowerCase().includes(search.toLowerCase())
          ).length
        }
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  materials: PropTypes.array.isRequired
};

export default Results;
