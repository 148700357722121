import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchEvents = createAsyncThunk(
  '/event/fetchEvents',
  async year => {
    try {
      const res = await axios.get(`${BASE_URL}/event?year=${year}`);
      return { year, data: res.data };
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const eventSlice = createSlice({
  name: 'user',
  initialState: {
    events: [],
    fetchedYears: []
  },
  reducers: {
    addEvent(state, action) {
      state.events.push(action.payload);
    },
    updateEvent(state, action) {
      state.events[state.events.findIndex(e => e.id === action.payload.id)] =
        action.payload;
    },
    deleteEvent(state, action) {
      state.events = state.events.filter(e => e.id != action.payload);
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events.push(...action.payload.data);
      state.fetchedYears.push(action.payload.year);
    });
  }
});

export const { addEvent, updateEvent, deleteEvent } = eventSlice.actions;

export default eventSlice.reducer;
