import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import getLocaleString from 'src/utils/getLocaleString';
import Results from './Results';
import Toolbar from './Toolbar';
import { BASE_URL } from '../../../constants/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CompanyListView = ({ user }) => {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL}/company`)
      .then(response => {
        setCompanies(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('companies', user.language)}
    >
      <Container maxWidth={false}>
        {isLoading ? (
          <Loader small />
        ) : (
          <>
            <Toolbar user={user} setSearch={setSearch} />
            <Box mt={3}>
              <Results user={user} companies={companies} search={search} />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default CompanyListView;
