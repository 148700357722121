import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from 'src/redux/userSlice';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = ({ user, isProfile, setUser }) => {
  const classes = useStyles();
  const { userId } = useParams();
  const [fetchedUser, setFetchedUser] = useState(null);
  const users = useSelector(state => state.user.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isProfile) {
      if (userId === '0') {
        axios
          .get(`${BASE_URL}/user/can-add-user`)
          .then(response => {
            if (!response.data) {
              toast.error(getLocaleString('userLimitReached', user.language));
            } else {
              setFetchedUser({
                id: 0,
                name: '',
                email: '',
                phone: '',
                color: '#2196f3',
                role: 'INSPECTOR',
                language: user.language,
                company: user.company
              });
            }
          })
          .catch(err => {
            if (err.response) {
              toast.error(err.response.data.message);
            } else {
              toast.error(err.message);
            }
          });
      } else {
        if (users === null) {
          dispatch(fetchUsers());
        } else {
          setFetchedUser(users.find(u => u.id == userId));
        }
      }
    }
  }, [userId, users]);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('account', user.language)}
    >
      {!isProfile && !fetchedUser ? (
        <></>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {userId !== '0' ? (
              <Grid item lg={4} md={6} xs={12}>
                <Profile
                  user={isProfile ? user : fetchedUser}
                  currentUser={user}
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              item
              lg={userId !== '0' ? 8 : 12}
              md={userId !== '0' ? 6 : 12}
              xs={12}
            >
              <ProfileDetails
                user={isProfile ? user : fetchedUser}
                currentUser={user}
                setUser={setUser}
                setFetchedUser={setFetchedUser}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default Account;
