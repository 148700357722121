import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';

import {
  DataSheetGrid,
  keyColumn,
  createTextColumn
} from 'react-datasheet-grid';
import Picker from 'react-month-picker';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import AddRowsCustomComponent from 'src/components/AddRowsCustomComponent';
import ContextMenuCustomComponent from 'src/components/ContextMenuCustomComponent';
import SelectColumnCustomComponent from 'src/components/SelectColumnCustomComponent';
import useWindowDimensions from 'src/utils/useWindowDimensions';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import MonthBox from './MonthBox';
import 'react-datasheet-grid/dist/style.css';
import 'src/styles/datasheetgrid.css';
import 'src/styles/monthBox.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const getSheetData = (data, header) => {
  const fields = Object.keys(data[0]);
  const sheetData = data.map(row => {
    return fields.map(fieldName => {
      return row[fieldName] ? row[fieldName] : '';
    });
  });
  sheetData.unshift(header);
  return sheetData;
};

const getColumnLetter = n => {
  const ordA = 'a'.charCodeAt(0);
  const ordZ = 'z'.charCodeAt(0);
  const len = ordZ - ordA + 1;

  let s = '';
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s.toUpperCase();
};

const selectColumn = options => ({
  component: SelectColumnCustomComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) =>
    options.choices.find(choice => choice.value === rowData)?.label,
  pasteValue: ({ value }) =>
    options.choices.find(choice => choice.label === value)?.value ?? null
});

const cellWidth = 48;

const parseInput = val => {
  if (val && val.length > 3) {
    return val.substring(0, 3);
  } else {
    return val;
  }
};

const today = new Date();

let columns = [];

const CheckIn = ({ user }) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const dataSheetRef = useRef();
  const dataSheetCloneRef = useRef();
  const monthPickerRef = useRef();

  const [dataSheetKey, setDataSheetKey] = useState(Math.random());
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [monthYear, setMonthYear] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1
  });
  const [pointage, setPointage] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const pickerLang = {
    months: [
      getLocaleString('jan', user.language),
      getLocaleString('feb', user.language),
      getLocaleString('mar', user.language),
      getLocaleString('apr', user.language),
      getLocaleString('may', user.language),
      getLocaleString('jun', user.language),
      getLocaleString('jul', user.language),
      getLocaleString('aug', user.language),
      getLocaleString('sep', user.language),
      getLocaleString('oct', user.language),
      getLocaleString('nov', user.language),
      getLocaleString('dec', user.language)
    ]
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/customer`)
      .then(response => {
        setCustomers(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });

    axios
      .get(`${BASE_URL}/employee`)
      .then(response => {
        setEmployees(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  useEffect(() => {
    const daysInMonth = new Date(monthYear.year, monthYear.month, 0).getDate();

    const columnBuilder = [
      {
        ...keyColumn(
          'employeeName',
          selectColumn({
            choices: employees.map(employee => ({
              value: employee.name,
              label: employee.name
            }))
          })
        ),
        title: getLocaleString('name', user.language),
        minWidth: 250
      }
    ];

    for (let i = 1; i <= daysInMonth; i++) {
      columnBuilder.push({
        ...keyColumn(
          `day${i.toString()}`,
          createTextColumn({
            continuousUpdates: true,
            parseUserInput: parseInput,
            parsePastedValue: parseInput
          })
        ),
        title: i.toString(),
        minWidth: cellWidth,
        maxWidth: cellWidth,
        width: cellWidth
      });
    }

    columns = columnBuilder;
    setDataSheetKey(Math.random());
  }, [monthYear, employees]);

  useEffect(() => {
    setDataSheetKey(Math.random());
  }, [columns]);

  useEffect(() => {
    if (selectedBuilding) {
      axios
        .get(
          `${BASE_URL}/pointage?month=${monthYear.month}&year=${monthYear.year}&buildingId=${selectedBuilding.id}`
        )
        .then(response => {
          setPointage(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, [monthYear, selectedBuilding]);

  const save = () => {
    if (hasChanged && selectedBuilding && !isSaving) {
      setIsSaving(true);
      setHasChanged(false);
      axios
        .post(
          `${BASE_URL}/pointage?month=${monthYear.month}&year=${monthYear.year}&buildingId=${selectedBuilding.id}`,
          pointage.pointageRows.map(pr => ({ ...pr, id: undefined }))
        )
        .then(response => {
          setPointage(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const makeText = m => {
    if (m && m.year && m.month) {
      return `${pickerLang.months[m.month - 1]}.${m.year}`;
    }
    return '?';
  };

  const download = () => {
    if (pointage && pointage.pointageRows.length > 0) {
      const header = columns.map(c => c.title);

      XlsxPopulate.fromBlankAsync().then(async workbook => {
        const sheet1 = workbook
          .sheet(0)
          .name(`${getLocaleString('checkIn', user.language)}`);

        const mappedPR = pointage.pointageRows.map(pr => ({
          name: pr.name,
          day1: pr.day1,
          day2: pr.day2,
          day3: pr.day3,
          day4: pr.day4,
          day5: pr.day5,
          day6: pr.day6,
          day7: pr.day7,
          day8: pr.day8,
          day9: pr.day9,
          day10: pr.day10,
          day12: pr.day12,
          day13: pr.day13,
          day14: pr.day14,
          day15: pr.day15,
          day16: pr.day16,
          day17: pr.day17,
          day18: pr.day18,
          day19: pr.day19,
          day20: pr.day20,
          day21: pr.day21,
          day22: pr.day22,
          day23: pr.day23,
          day24: pr.day24,
          day25: pr.day25,
          day26: pr.day26,
          day27: pr.day27,
          day28: pr.day28,
          day29: pr.day29,
          day30: pr.day30,
          day31: pr.day31
        }));
        const sheetData = getSheetData(mappedPR, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell('A1').value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = getColumnLetter(totalColumns - 1);
        sheet1.row(1).style('bold', true);
        sheet1.range(`A1:${endColumn}1`).style('fill', 'BFBFBF');
        range.style('border', true);
        sheet1.column('A').width(33);
        for (let i = 2; i <= sheetData[0].length; i++) {
          sheet1.column(i).width(5);
        }
        return workbook.outputAsync().then(res => {
          saveAs(
            res,
            `${getLocaleString('checkIn', user.language)} ${
              selectedCustomer.name
            } ${selectedBuilding.name} ${monthYear.month}-${
              monthYear.year
            }.xlsx`
          );
        });
      });
    }
  };

  const handleMonthChange = (year, month) => {
    setMonthYear({ year, month });
  };

  const getHeight = () => {
    if (width >= 960) {
      return height - 250;
    } else if (width >= 600) {
      return height - 300;
    } else {
      return height - 310;
    }
  };

  const handleCustomerChange = e => {
    setSelectedBuilding(null);
    setSelectedCustomer(
      customers.find(customer => customer.id === e.target.value)
    );
  };

  const handleBuildingChange = e => {
    setSelectedBuilding(
      selectedCustomer.buildings.find(
        building => building.id === e.target.value
      )
    );
  };

  const handleRowsChange = newRows => {
    setPointage(currentPointage => ({
      ...currentPointage,
      pointageRows: newRows
    }));
    setHasChanged(true);
  };

  return (
    <>
      <div ref={dataSheetRef}>
        <Page className={classes.root} title="Checkin">
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <div className="edit" disabled={hasChanged}>
                  <Picker
                    ref={monthPickerRef}
                    years={{ min: 2022 }}
                    value={monthYear}
                    lang={pickerLang.months}
                    onChange={handleMonthChange}
                  >
                    <MonthBox
                      value={makeText(monthYear)}
                      onClick={() => monthPickerRef.current.show()}
                    />
                  </Picker>
                </div>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="customer-select-label">
                    {getLocaleString('customer', user.language)}
                  </InputLabel>
                  <Select
                    labelId="customer-select-label"
                    id="customer-select"
                    value={selectedCustomer ? selectedCustomer.id : ''}
                    label="Customer"
                    onChange={handleCustomerChange}
                    disabled={hasChanged}
                  >
                    {customers.map(customer => (
                      <MenuItem key={customer.id} value={customer.id}>
                        {customer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                {selectedCustomer !== null ? (
                  <FormControl fullWidth>
                    <InputLabel id="building-select-label">
                      {getLocaleString('building', user.language)}
                    </InputLabel>
                    <Select
                      labelId="building-select-label"
                      id="building-select"
                      value={selectedBuilding ? selectedBuilding.id : ''}
                      label="Building"
                      onChange={handleBuildingChange}
                      disabled={hasChanged}
                    >
                      {selectedCustomer.buildings.map(building => (
                        <MenuItem key={building.id} value={building.id}>
                          {building.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  id="exportPdfBtn"
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={download}
                  disabled={!selectedCustomer || !selectedBuilding}
                >
                  {getLocaleString('download', user.language)}
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  id="saveBtn"
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={save}
                  disabled={!hasChanged || !selectedBuilding || isSaving}
                >
                  {getLocaleString('save', user.language)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <div id="dataSheetGrid">
                  {columns.length > 0 && pointage ? (
                    <DataSheetGrid
                      key={dataSheetKey}
                      height={height ? getHeight() : 400}
                      value={pointage.pointageRows}
                      onChange={handleRowsChange}
                      columns={columns}
                      addRowsComponent={props => (
                        <AddRowsCustomComponent
                          addRows={props.addRows}
                          addText={getLocaleString('add', user.language)}
                          lignesText={getLocaleString('rows', user.language)}
                        />
                      )}
                      contextMenuComponent={props => (
                        <ContextMenuCustomComponent
                          clientX={props.clientX}
                          clientY={props.clientY}
                          close={props.close}
                          items={props.items}
                          contextMenuText={{
                            deleteRow: getLocaleString(
                              'deleteRow',
                              user.language
                            ),
                            deleteRows1: getLocaleString(
                              'deleteRows',
                              user.language
                            ),
                            until: getLocaleString('until', user.language),
                            insertRowBelow: getLocaleString(
                              'insertRowBelow',
                              user.language
                            ),
                            duplicateRow: getLocaleString(
                              'duplicateRow',
                              user.language
                            ),
                            duplicateRows1: getLocaleString(
                              'duplicateRows',
                              user.language
                            )
                          }}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
        </Page>
      </div>
      <div ref={dataSheetCloneRef} style={{ visibility: 'hidden' }} />
    </>
  );
};

export default CheckIn;
