import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
  Select,
  Input,
  Chip,
  MenuItem
} from '@material-ui/core';
import { MapPin as MapIcon, Tag as TagIcon } from 'react-feather';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from 'src/constants/api';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  multiSelect: {
    minHeight: '55px'
  }
}));

const Toolbar = ({ className, user, customer, setCustomer, ...rest }) => {
  const classes = useStyles();
  const [builtingTypes, setBuildingTypes] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/buildingtype`)
      .then(response => {
        setBuildingTypes(response.data);
      })
      .catch(err => {
        toast.error(err.reponse.data.message);
      });
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Formik
          initialValues={{
            name: '',
            address: '',
            buildingTypes: []
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            address: Yup.string()
              .max(255)
              .required(getLocaleString('nameRequired', user.language))
          })}
          onSubmit={(values, actions) => {
            axios
              .post(`${BASE_URL}/building`, {
                ...values,
                customerId: customer.id
              })
              .then(response => {
                toast.success(
                  getLocaleString('buildingCreated', user.language)
                );
                const newCustomer = { ...customer };
                newCustomer.buildings = [
                  ...newCustomer.buildings,
                  response.data
                ];
                setCustomer(newCustomer);
                actions.resetForm();
                actions.setSubmitting(false);
              })
              .catch(err => {
                toast.error(err.reponse.data.message);
                actions.setSubmitting(false);
              });
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            values
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Box>
                        <TextField
                          fullWidth
                          name="address"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                  <MapIcon />
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          placeholder={getLocaleString(
                            'address',
                            user.language
                          )}
                          variant="outlined"
                          required
                          label={getLocaleString('address', user.language)}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box>
                        <Select
                          fullWidth
                          className={classes.multiSelect}
                          multiple
                          value={values.buildingTypes}
                          name="buildingTypes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          input={<Input />}
                          renderValue={selected => (
                            <div className={classes.chips}>
                              {selected.map(value => (
                                <Chip
                                  key={value}
                                  label={
                                    builtingTypes.find(b => b.id === value).name
                                  }
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250
                              }
                            }
                          }}
                        >
                          {builtingTypes.map(buildingType => (
                            <MenuItem
                              key={buildingType.id}
                              value={buildingType.id}
                            >
                              {buildingType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box>
                        <TextField
                          fullWidth
                          name="name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                  <TagIcon />
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          placeholder={getLocaleString('name', user.language)}
                          variant="outlined"
                          label={getLocaleString('name', user.language)}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        style={{ height: '100%' }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={isSubmitting || !isValid}
                          style={{ width: '100%' }}
                          type="submit"
                        >
                          {getLocaleString('addBuilding', user.language)}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
