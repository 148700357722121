import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  DialogActions,
  makeStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from 'src/constants/api';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}));

const ExceptionForm = ({
  user,
  eventToUpdate,
  setEventToUpdate,
  exceptionToUpdate,
  setExceptionToUpdate,
  getInitialEndTime,
  isReadOnly,
  className
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [yupSchema, setYupSchema] = useState(null);
  const [currentYupSchema, setCurrentYupSchema] = useState(null);

  const nonCancelSchema = Yup.object().shape({
    date: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language)),
    startTime: Yup.date()
      .typeError(getLocaleString('startTimeRequired', user.language))
      .required(getLocaleString('startTimeRequired', user.language)),
    endTime: Yup.date()
      .typeError(getLocaleString('endTimeRequired', user.language))
      .required(getLocaleString('endTimeRequired', user.language))
      .when(
        'startTime',
        (startTime, schema) =>
          startTime &&
          schema.min(
            startTime,
            getLocaleString('endTimeAfterStart', user.language)
          )
      )
  });

  const cancelSchema = Yup.object().shape({
    date: Yup.date()
      .typeError(getLocaleString('dateRequired', user.language))
      .required(getLocaleString('dateRequired', user.language))
  });

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = (e, exception) => {
    if (
      !e.target.classList.contains('MuiButton-label') &&
      !e.target.classList.contains('MuiButtonBase-root')
    ) {
      setExceptionToUpdate(exception);
    }
  };

  const handleExceptionDelete = exceptionId => {
    axios
      .delete(
        `${BASE_URL}/repeating-event/${eventToUpdate.id}/exception/${exceptionId}`
      )
      .then(response => {
        setExceptionToUpdate(null);
        setEventToUpdate(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleAddExceptionClick = () => {
    setExceptionToUpdate({
      id: 0,
      date: new Date(),
      startTime: new Date(),
      endTime: getInitialEndTime(),
      cancelled: false
    });
  };

  const FormManager = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.cancelled) {
        if (currentYupSchema !== 'cancelled') {
          setCurrentYupSchema('cancelled');
          setYupSchema(cancelSchema);
        }
      } else {
        if (currentYupSchema !== 'noncancelled') {
          setCurrentYupSchema('noncancelled');
          setYupSchema(nonCancelSchema);
        }
      }
    }, [values.cancelled]);
    return null;
  };

  return (
    <>
      {exceptionToUpdate ? (
        <Formik
          initialValues={{
            date: exceptionToUpdate.date,
            startTime: exceptionToUpdate.startTime,
            endTime: exceptionToUpdate.endTime,
            cancelled: exceptionToUpdate.cancelled
          }}
          validationSchema={yupSchema}
          onSubmit={(values, actions) => {
            const exception = {
              date: moment(values.date).format('YYYY-MM-DD HH:mm:SS'),
              startTime: moment(values.startTime).format('YYYY-MM-DD HH:mm:SS'),
              endTime: moment(values.endTime).format('YYYY-MM-DD HH:mm:SS'),
              cancelled: values.cancelled
            };

            let axiosReq;
            if (exceptionToUpdate.id === 0) {
              axiosReq = axios.post(
                `${BASE_URL}/repeating-event/${eventToUpdate.id}/exception`,
                exception
              );
            } else {
              axiosReq = axios.put(
                `${BASE_URL}/repeating-event/${eventToUpdate.id}/exception/${exceptionToUpdate.id}`,
                exception
              );
            }

            axiosReq
              .then(response => {
                setExceptionToUpdate(null);
                setEventToUpdate(response.data);
              })
              .catch(err => {
                if (err.response) {
                  toast.error(err.response.data.message);
                } else {
                  toast.error(err.message);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            isValid,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DatePicker
                    fullWidth
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    name="date"
                    id="date"
                    readOnly={isReadOnly()}
                    InputProps={isReadOnly() ? { readOnly: true } : {}}
                    label={getLocaleString('date', user.language)}
                    value={values.date}
                    onBlur={handleBlur}
                    onChange={val => {
                      setFieldValue('date', val);
                    }}
                  />
                </Grid>
                {!values.cancelled ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <TimePicker
                        fullWidth
                        error={Boolean(touched.startTime && errors.startTime)}
                        helperText={touched.startTime && errors.startTime}
                        ampm={false}
                        label={getLocaleString('startTime', user.language)}
                        margin="normal"
                        name="startTime"
                        id="startTime"
                        value={values.startTime}
                        onBlur={handleBlur}
                        readOnly={isReadOnly()}
                        InputProps={isReadOnly() ? { readOnly: true } : {}}
                        onChange={val => {
                          setFieldValue('startTime', val);
                        }}
                        cancelLabel={getLocaleString('cancel', user.language)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TimePicker
                        fullWidth
                        error={Boolean(touched.endTime && errors.endTime)}
                        helperText={touched.endTime && errors.endTime}
                        ampm={false}
                        label={getLocaleString('endTime', user.language)}
                        margin="normal"
                        name="endTime"
                        id="endTime"
                        value={values.endTime}
                        onBlur={handleBlur}
                        readOnly={isReadOnly()}
                        InputProps={isReadOnly() ? { readOnly: true } : {}}
                        onChange={val => {
                          setFieldValue('endTime', val);
                        }}
                        cancelLabel={getLocaleString('cancel', user.language)}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="cancelled"
                        id="cancelled"
                        checked={values.cancelled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="primary"
                      />
                    }
                    label={getLocaleString('cancelEvent', user.language)}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                {exceptionToUpdate.id !== 0 ? (
                  <Button
                    onClick={() => handleExceptionDelete(exceptionToUpdate.id)}
                    color="primary"
                    style={{
                      color: '#d11a2a',
                      position: 'absolute',
                      left: 10
                    }}
                  >
                    {getLocaleString('delete', user.language)}
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  onClick={() => setExceptionToUpdate(null)}
                  color="primary"
                >
                  {getLocaleString('cancel', user.language)}
                </Button>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  color="primary"
                  style={
                    isSubmitting || !isValid
                      ? { color: '#525252' }
                      : { color: '#129c09' }
                  }
                  autoFocus
                  disabled={isSubmitting || !isValid}
                >
                  {exceptionToUpdate.id !== 0
                    ? getLocaleString('update', user.language)
                    : getLocaleString('add', user.language)}
                </Button>
              </DialogActions>
              <FormManager />
            </form>
          )}
        </Formik>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              type="button"
              onClick={handleAddExceptionClick}
            >
              {getLocaleString('createException', user.language)}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {getLocaleString('date', user.language)}
                    </TableCell>
                    <TableCell align="center">
                      {getLocaleString('action', user.language)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventToUpdate.exceptions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(exception => (
                      <TableRow
                        key={exception.id}
                        hover
                        style={{ cursor: 'pointer' }}
                        onClick={e => handleRowClick(e, exception)}
                      >
                        <TableCell align="center">
                          {moment(exception.date).format('DD/MM/yyyy')}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            color="primary"
                            style={{
                              color: 'white',
                              backgroundColor: '#d11a2a'
                            }}
                            onClick={() => handleExceptionDelete(exception.id)}
                          >
                            {getLocaleString('delete', user.language)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              component="div"
              labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
              count={eventToUpdate.exceptions.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ExceptionForm;
